import {
  Box,
  FormHelperText,
  Input,
  InputGroup,
  InputRightAddon,
  Spinner,
} from "@chakra-ui/react";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { BLACKLIST_CONTACTS_TYPE_VALUE_EMAIL } from "../../../constants/blacklistContact";
import { useApi } from "../../../hooks/useApi";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import { Icon } from "../../Icon";

export const EmailWithBlacklistVerify = forwardRef(
  /**
   * @param {import("../../../components/ReactHookForm/FormControlRHF").FieldProps<string>} props
   */
  function EmailWithBlacklistVerify({ name, onChange, value, ...fields }, ref) {
    const intl = useIntl();

    const [isMailBlacklisted, setIsMailBlacklisted] = useState(
      /** @type {boolean | null} */ (null),
    );

    const { toastError } = useRequest();

    const [email, setEmail] = useState(value);

    useEffect(() => {
      // Reset the state when the value changes and update the email (in case of a controlled component)
      setIsMailBlacklisted(null);

      if (value !== email) {
        setEmail(value);
      }
    }, [email, value]);

    const debouncedEmail = useDebounce(email, { delay: 1000 });

    /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("../../../types/BlacklistContact").BlacklistContactVerifyData>} */
    const { swrResponse } = useApi(
      debouncedEmail.length >= 5 && debouncedEmail.includes("@")
        ? generateApiUrl({
            id: "@blacklistContacts.verify",
            query: {
              email: debouncedEmail,
              type: BLACKLIST_CONTACTS_TYPE_VALUE_EMAIL,
            },
          })
        : null,
      {
        swrConfig: {
          keepPreviousData: true,
          onSuccess: (data) => {
            setIsMailBlacklisted(data.data?.blacklist);
          },
          onError: () => {
            setIsMailBlacklisted(null);
            toastError({
              title: intl.formatMessage({
                defaultMessage:
                  "Une erreur est survenue lors de la vérification de l'email",
              }),
            });
          },
        },
      },
    );

    const handleChange = useCallback(
      (e) => {
        setEmail(e.target.value);
        onChange(e);
      },
      [onChange],
    );

    return (
      <>
        <InputGroup ref={ref}>
          <Input
            {...fields}
            type="email"
            value={value}
            placeholder={intl.formatMessage({
              defaultMessage: "Adresse email",
            })}
            onChange={handleChange}
            color={isMailBlacklisted ? "red.500" : undefined}
          />

          <InputRightAddon>
            {swrResponse.isValidating ? (
              <Box>
                <Spinner size="sm" />
              </Box>
            ) : (
              <Box>
                {isMailBlacklisted && <Icon icon="ms_report" color="red.500" />}
              </Box>
            )}
          </InputRightAddon>
        </InputGroup>
        {isMailBlacklisted && (
          <FormHelperText color="red.500" mt=".25rem" pl="1rem">
            {intl.formatMessage({
              defaultMessage: "Cet email a été signalé comme frauduleux",
            })}
          </FormHelperText>
        )}
      </>
    );
  },
);
