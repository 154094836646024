import { Box, Flex, IconButton } from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { RoomPictureLazy } from "../../SearchHotels/RoomPictureLazy/RoomPictureLazy";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/RoomPicture").RoomPicture[]} roomPictures
 * @property {number | null} pictureIndex
 * @property {import("react").Dispatch<import("react").SetStateAction<number | null>>} setPictureIndex
 * @property {boolean} isMobileLandscape
 */
/**
 * @param {Props} props
 */
export function RoomPicturesSlider({
  roomPictures,
  pictureIndex,
  setPictureIndex,
  isMobileLandscape,
}) {
  const intl = useIntl();
  const handleClickPrev = useCallback(() => {
    setPictureIndex((prevIndex) => {
      if (prevIndex === null) {
        return null;
      }
      return prevIndex - 1;
    });
  }, [setPictureIndex]);

  const handleClickNext = useCallback(() => {
    setPictureIndex((prevIndex) => {
      if (prevIndex === null) {
        return null;
      }
      return prevIndex + 1;
    });
  }, [setPictureIndex]);

  return (
    <Box
      position="relative"
      height="100%"
      margin={isMobileLandscape ? "0 auto" : "0"}
      width={isMobileLandscape ? "350px" : "auto"}>
      {roomPictures.map((roomPicture, index) => (
        <Flex
          key={roomPicture.id}
          justifyContent="center"
          alignItems="center"
          maxWidth="100%"
          height="100%"
          opacity={index === pictureIndex ? 1 : 0}
          pointerEvents={index === pictureIndex ? "auto" : "none"}
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          transition="opacity 0.5s"
          flexDir="column">
          <Flex
            justifyContent="center"
            alignItems="center"
            width="100%"
            position="relative">
            <Box maxWidth="1100px" flexGrow={1}>
              <RoomPictureLazy
                roomPicture={roomPicture}
                maxContainerWidth={1100}
              />
            </Box>
          </Flex>
        </Flex>
      ))}

      <Box
        display={pictureIndex === 0 ? "none" : "block"}
        position="absolute"
        left={{ base: "0px", md: "16px" }}
        top="50%"
        transform={`translateY(-50%)`}
        borderRadius="full">
        <IconButton
          borderRadius="full"
          icon={<Icon icon="ms_chevron_left" size="24px" color="white" />}
          variant="ghost"
          backgroundColor="rgba(11, 13, 27, .5)"
          aria-label={intl.formatMessage({
            defaultMessage: "Précédent",
          })}
          onClick={handleClickPrev}
          _hover={{ backgroundColor: "rgba(11, 13, 27, .7)" }}
        />
      </Box>

      <Box
        display={pictureIndex === roomPictures.length - 1 ? "none" : "block"}
        position="absolute"
        right={{ base: "0px", md: "16px" }}
        top="50%"
        transform={`translateY(-50%)`}
        borderRadius="full">
        <IconButton
          borderRadius="full"
          icon={<Icon icon="ms_chevron_right" size="24px" color="white" />}
          variant="ghost"
          backgroundColor="rgba(11, 13, 27, .5)"
          aria-label={intl.formatMessage({ defaultMessage: "Suivant" })}
          onClick={handleClickNext}
          _hover={{ backgroundColor: "rgba(11, 13, 27, .7)" }}
        />
      </Box>
    </Box>
  );
}
