import { Box, Text, Wrap } from "@chakra-ui/react";
import { RTable } from "@raiden/library-ui/components/RTable";
import { RTd } from "@raiden/library-ui/components/RTable/RTd";
import { RTr } from "@raiden/library-ui/components/RTable/RTr";
import { AMENITIES_CATEGORY_VALUE_PARKING } from "@raiden/library-ui/constants/amenities";
import { hotelsPaymentModeMessage } from "@raiden/library-ui/constants/hotels";
import { CURRENCY_OPTIONS } from "@raiden/library-ui/constants/intl";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { memo, useMemo } from "react";
import { FormattedList, FormattedMessage, useIntl } from "react-intl";

export const HotelViewRoomDetail = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
   * @property {import("@raiden/library-ui/types/Room").Room | null} room
   */
  /**
   * @param {Props} props
   */
  function HotelViewRoomDetail({ hotel, room }) {
    const intl = useIntl();

    const translate = useTranslate();

    const parkingAmenitiesNames = useMemo(() => {
      /** @type {string[]} */
      const amenityNames = [];
      if (!room) {
        return amenityNames;
      }
      room.amenities?.forEach((amenity) => {
        if (amenity.category !== AMENITIES_CATEGORY_VALUE_PARKING) {
          return;
        }
        const name = translate(amenity.name);
        if (name) {
          amenityNames.push(name);
        }
      });
      return amenityNames;
    }, [room, translate]);

    return (
      <Box>
        <RTable isStriped={false} useDivider={false}>
          {room?.agr && (
            <RTr>
              <RTd>
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage defaultMessage="Numéro d'agrément" />
                </Text>
              </RTd>

              <RTd>
                <Text variant="text-sm">
                  <FormattedMessage
                    defaultMessage="{agr}"
                    values={{
                      agr: room.agr,
                    }}
                  />
                </Text>
              </RTd>
            </RTr>
          )}

          {room?.recording_number && (
            <RTr>
              <RTd>
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage defaultMessage="Numéro d'enregistrement" />
                </Text>
              </RTd>

              <RTd>
                <Text variant="text-sm">
                  <FormattedMessage
                    defaultMessage="{recording_number}"
                    values={{
                      recording_number: room.recording_number,
                    }}
                  />
                </Text>
              </RTd>
            </RTr>
          )}

          {room?.surface && (
            <RTr>
              <RTd>
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage defaultMessage="Surface" />
                </Text>
              </RTd>

              <RTd>
                <Text variant="text-sm">
                  <FormattedMessage
                    defaultMessage="{surface} m²"
                    values={{
                      surface: room.surface,
                    }}
                  />
                </Text>
              </RTd>
            </RTr>
          )}

          {room?.baby_reception && (
            <RTr>
              <RTd>
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage defaultMessage="Équipé pour bébés" />
                </Text>
              </RTd>

              <RTd>
                <Text variant="text-sm">
                  <FormattedMessage defaultMessage="Oui" />
                </Text>
              </RTd>
            </RTr>
          )}

          {room?.composition_infos && room.composition_infos.count_beds > 0 && (
            <RTr>
              <RTd>
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage defaultMessage="Lits" />
                </Text>
              </RTd>

              <RTd>
                <Wrap spacing="8px">
                  <Text variant="text-sm">
                    <span>{room.composition_infos.count_beds}</span>
                  </Text>

                  <Text variant="text-sm" color="gray.500">
                    {room.composition_infos.beds.length > 0 && (
                      <span>
                        (
                        {intl.formatList(
                          room.composition_infos.beds.map(
                            (bed) =>
                              `${bed.quantity} x ${translate(
                                bed.name,
                              )?.toLocaleLowerCase()}`,
                          ),
                          {
                            type: "conjunction",
                          },
                        )}
                        )
                      </span>
                    )}
                  </Text>
                </Wrap>
              </RTd>
            </RTr>
          )}

          {room?.composition_infos && room.composition_infos.count_wc > 0 && (
            <RTr>
              <RTd>
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage defaultMessage="WCs" />
                </Text>
              </RTd>

              <RTd>
                <Text variant="text-sm">{room.composition_infos.count_wc}</Text>
              </RTd>
            </RTr>
          )}

          {parkingAmenitiesNames.length > 0 && (
            <RTr>
              <RTd>
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage defaultMessage="Stationnement" />
                </Text>
              </RTd>

              <RTd>
                <Text variant="text-sm">
                  <FormattedList value={parkingAmenitiesNames} />
                </Text>
              </RTd>
            </RTr>
          )}

          {hotel.payment_modes && hotel.payment_modes.length > 0 && (
            <RTr>
              <RTd>
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage defaultMessage="Modes de paiement propriétaire" />
                </Text>
              </RTd>

              <RTd>
                <Text variant="text-sm">
                  <FormattedList
                    value={hotel.payment_modes.map((mode) =>
                      intl.formatMessage(hotelsPaymentModeMessage, { mode }),
                    )}
                  />
                </Text>
              </RTd>
            </RTr>
          )}

          {room?.guarantee && room.guarantee > 0 && (
            <RTr>
              <RTd>
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage defaultMessage="Dépôt de garantie" />
                </Text>
              </RTd>

              <RTd>
                <Text variant="text-sm">
                  {intl.formatNumber(room.guarantee, CURRENCY_OPTIONS)}
                </Text>
              </RTd>
            </RTr>
          )}
        </RTable>
      </Box>
    );
  },
);
