import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { RevealVertically } from "@raiden/library-ui/components/Animations/RevealVertically";
import { Icon } from "@raiden/library-ui/components/Icon";
import { ApiErrorProvider } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import { useCallback, useRef, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { FormattedMessage, useIntl } from "react-intl";
import { HotelViewCustomer } from "../../Hotels/View/Customer";
import { WebHotelContactCreateForm } from "./Form";
import { WebHotelContactCreateHandler } from "./Handler";
import { WebHotelContactPublicPhone } from "./PublicPhone";

const FORM_ID = "web-hotel-contact-create-form";

/**
 * @typedef {object} Props
 * @property {number} hotelId
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {import("@raiden/library-ui/types/Customer").Customer | null} customer
 * @property {number} roomId
 * @property {string} [checkin]
 * @property {string} [checkout]
 * @property {boolean} withWebsite
 * @property {import("@raiden/library-ui/types/Room").Room[]} roomList
 */
/**
 * @param {Props} props
 */
export function WebHotelContactCreateModal({
  hotelId,
  isOpen,
  onClose: _onClose,
  customer,
  roomId,
  checkin,
  checkout,
  withWebsite,
  roomList,
}) {
  const intl = useIntl();

  /** @type {import("react").MutableRefObject<HTMLButtonElement | null>} */
  const ref = useRef(null);

  const [success, setSuccess] = useState(false);

  const [phoneData, setPhoneData] = useState(
    /** @type {{data: import("@raiden/library-ui/types/Customer").CustomerPublicPhone} | null} */
    (null),
  );

  const handleSuccess = useCallback(() => {
    setSuccess(true);
  }, []);

  const onClose = useCallback(() => {
    setSuccess(false);
    setPhoneData(null);
    _onClose();
  }, [_onClose]);

  return (
    <Modal
      initialFocusRef={ref}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="3xl">
      <ModalOverlay />

      <ModalContent mx="1rem">
        <ApiErrorProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={
              process.env.NEXT_PUBLIC_RECAPTCHA_V3_PUBLIC_KEY ?? ""
            }>
            <WebHotelContactCreateHandler
              hotelId={hotelId}
              roomId={roomId}
              defaultCheckin={checkin}
              defaultCheckout={checkout}
              onSuccess={handleSuccess}>
              {({ form, roomId, submit, isLoading }) => (
                <>
                  <ModalHeader borderBottomWidth="1px" p="1rem">
                    <HStack w="full">
                      <Box flexGrow={1}>
                        <Text variant="h4-public">
                          <FormattedMessage defaultMessage="Contacter le propriétaire" />
                        </Text>
                      </Box>

                      <IconButton
                        onClick={onClose}
                        variant="outline"
                        borderRadius="full"
                        icon={<Icon icon="ms_close" />}
                        alignSelf="flex-start"
                        aria-label={intl.formatMessage({
                          defaultMessage: "Fermer",
                        })}
                      />
                    </HStack>
                  </ModalHeader>

                  <ModalBody py="32px">
                    <Stack spacing="32px">
                      {customer && (
                        <Stack spacing="8px">
                          <HotelViewCustomer
                            customer={customer}
                            withPublicInfo={false}
                            withWebsite={withWebsite}
                            withWebsiteInDescription={true}
                            hotelId={hotelId}
                          />
                        </Stack>
                      )}

                      {customer?.has_public_phone && (
                        <WebHotelContactPublicPhone
                          customerId={customer.id}
                          phoneData={phoneData}
                          setPhoneData={setPhoneData}
                          hotelId={hotelId}
                        />
                      )}

                      <Box>
                        <RevealVertically isVisible={success}>
                          <Alert status="success">
                            <AlertIcon />

                            <AlertDescription>
                              <FormattedMessage defaultMessage="Votre message a bien été envoyé." />
                            </AlertDescription>
                          </Alert>
                        </RevealVertically>

                        <RevealVertically isVisible={!success}>
                          <form id={FORM_ID} onSubmit={submit}>
                            <Stack spacing=".75rem">
                              <Text variant="h5">
                                {intl.formatMessage({
                                  defaultMessage: "Contact par message",
                                })}
                              </Text>

                              <WebHotelContactCreateForm
                                form={form}
                                roomId={roomId}
                                roomList={roomList}
                              />
                            </Stack>
                          </form>
                        </RevealVertically>
                      </Box>
                    </Stack>
                  </ModalBody>

                  <ModalFooter borderTopWidth="1px">
                    <Wrap>
                      {success && (
                        <Button onClick={onClose} colorScheme="brandSecondary">
                          <FormattedMessage defaultMessage="Fermer" />
                        </Button>
                      )}

                      {!success && (
                        <>
                          <Button onClick={onClose} colorScheme="gray">
                            <FormattedMessage defaultMessage="Annuler" />
                          </Button>

                          <Button
                            ref={ref}
                            type="submit"
                            form={FORM_ID}
                            isLoading={isLoading}
                            colorScheme="brandSecondary">
                            <FormattedMessage defaultMessage="Contacter" />
                          </Button>
                        </>
                      )}
                    </Wrap>
                  </ModalFooter>
                </>
              )}
            </WebHotelContactCreateHandler>
          </GoogleReCaptchaProvider>
        </ApiErrorProvider>
      </ModalContent>
    </Modal>
  );
}
