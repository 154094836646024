import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import { memo } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";

export const HotelViewInfoItem = memo(
  /**
   * @typedef {object} Props
   * @property {string} icon
   * @property {string} label
   * @property {string | import("react").ReactElement} [info]
   * @property {string} [labelVariant]
   */
  /**
   * @param {Props} props
   */
  function HotelViewInfoItem({ icon, label, info, labelVariant }) {
    const intl = useIntl();

    return (
      <VStack spacing="4px">
        <Icon icon={icon} color="gray.500" size="1.5rem" />

        <Text textAlign="center" variant={labelVariant}>
          {label}
        </Text>

        {info && (
          <Popover strategy="fixed">
            <PopoverTrigger>
              <IconButton
                icon={<Icon icon="ms_info" />}
                size="xs"
                aria-label={intl.formatMessage({
                  defaultMessage: "Plus d'information",
                })}
              />
            </PopoverTrigger>

            <PopoverContent>
              <PopoverArrow />

              <PopoverBody>
                {typeof info === "string" ? (
                  <Text>{capitalize(info)}</Text>
                ) : (
                  info
                )}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </VStack>
    );
  },
);
