import { Box, Button, LightMode, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { Href } from "../../../components/Href";

export function Insurance() {
  return (
    <>
      <Text
        colorScheme="brandPrimary"
        as="span"
        variant="text-sm"
        mb="8px"
        textAlign="center">
        <FormattedMessage
          defaultMessage="Ne perdez plus d'argent sur vos réservations ! <text>Annulation, interruption de séjour, casse en cours de séjour...</text> <text>Pensez à l'assurance annulation</text>"
          values={{
            text: (chunk) => {
              return <p>{chunk}</p>;
            },
          }}
        />
      </Text>

      <Box textAlign="center">
        <LightMode>
          <Href
            href="https://clevacances.xplorassur.com/nos-offres"
            target="_blank">
            <Button variant="outline">
              <Text variant="text-sm">
                <FormattedMessage defaultMessage="Plus d'informations" />
              </Text>
            </Button>
          </Href>
        </LightMode>
      </Box>
    </>
  );
}
