import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Portal,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  Wrap,
} from "@chakra-ui/react";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import { Icon } from "@raiden/library-ui/components/Icon";
import { ApiErrorProvider } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import {
  AMENITIES_CATEGORY_VALUE_ACCESSIBILITY,
  AMENITIES_CATEGORY_VALUE_BABY,
  AMENITIES_CATEGORY_VALUE_CHARGING_STATION,
  AMENITIES_CATEGORY_VALUE_COMFORT,
  AMENITIES_CATEGORY_VALUE_HIGHTECH,
  AMENITIES_CATEGORY_VALUE_HOBBY,
  AMENITIES_CATEGORY_VALUE_INSIDE,
  AMENITIES_CATEGORY_VALUE_LABEL,
  AMENITIES_CATEGORY_VALUE_OUTSIDE,
  AMENITIES_CATEGORY_VALUE_PARKING,
  AMENITIES_CATEGORY_VALUE_PARTNER,
  AMENITIES_CATEGORY_VALUE_PROXIMITY,
  AMENITIES_CATEGORY_VALUE_SECURITY,
  AMENITIES_CATEGORY_VALUE_SERVICE,
  AMENITIES_CATEGORY_VALUE_SUSTAINABLE,
  AMENITIES_CATEGORY_VALUE_THEME,
} from "@raiden/library-ui/constants/amenities";
import {
  HOTELS_MEDIA_TYPE_VALUE_VIDEO,
  HOTELS_MEDIA_TYPE_VALUE_VIRTUAL_VISIT,
} from "@raiden/library-ui/constants/hotels";
import { useRouterNumber } from "@raiden/library-ui/hooks/router/useRouterNumber";
import { useRouterString } from "@raiden/library-ui/hooks/router/useRouterString";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";
import dayjs from "dayjs";
import dynamic from "next/dynamic";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Card } from "../../../components/Card/Card";
import { CenteredContent } from "../../../components/CenteredContent";
import { HotelReviewSummary } from "../../../components/Hotels/ReviewSummary";
import { HotelTags } from "../../../components/Hotels/Tags";
import { PageHeaderPublic } from "../../../components/PageHeaderPublic";
import { PageLayout } from "../../../components/PageLayout";
import { Share } from "../../../components/Share";
import { useCart } from "../../../hooks/useCart";
import { useHeader } from "../../../hooks/useHeader";
import { CartBookingsCreateHandler } from "../../CartBookings/Create/Handler";
import { HotelViewAmenitiesTabs } from "./AmenitiesTabs";
import { ButtonSection } from "./ButtonSection";
import { CartBookingBottomBar } from "./CartBookingBottomBar";
import { CartBookingSidebar } from "./CartBookingSidebar";
import { HotelViewCustomer } from "./Customer";
import { HotelViewDescriptions } from "./Descriptions";
import { HotelViewHotelInfo } from "./HotelInfo";
import { HotelViewMedias } from "./Medias";
import { ModalBookingSuccess } from "./ModalBookingSuccess";
import { HotelViewPictures } from "./Pictures";
import { HotelViewProximityAmenities } from "./ProximityAmenities";
import { HotelViewQuickSummary } from "./QuickSummary";
import { HotelViewReviews } from "./Reviews/Reviews";
import { HotelViewAmenities } from "./RoomAmenities";
import { HotelViewRoomCompositions } from "./RoomCompositions";
import { HotelViewRoomDetail } from "./RoomDetail";
import { HotelViewRooms } from "./Rooms";
import { HotelViewRoomServices } from "./RoomServices";
import { HotelViewSection } from "./Section";
import { HotelViewTopAmenity } from "./TopAmenity";
import { plansIsAliasExpert } from "@raiden/library-ui/helpers/hotels/plans";
import { ExpandableContainer } from "../../../components/ExpandableContainer/ExpandableContainer";
import { Href } from "../../../components/Href";
import { getDepartmentSlug } from "@raiden/library-ui/helpers/departments/getSlug";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import { getHotelSlug } from "@raiden/library-ui/helpers/hotels/getSlug";
import { useCreateHotelMetricsEvent } from "../../../hooks/hotel/useCreateHotelMetricsEvent";
import { HOTEL_METRICS_EVENT_TYPE_VALUE_VIEW } from "@raiden/library-ui/constants/hotelMetrics";

const HotelViewMapDynamic = dynamic(
  () => import("./Map").then((mod) => mod.HotelViewMap),
  {
    ssr: false,
  },
);

/**
 * @typedef {"description" | "rooms" | "medias" | "equipments" | "localisation" | "supplements" | "reviews"} SectionValue
 */

const ID_SECTION_DESCRIPTION = "description";
const ID_SECTION_ROOMS = "rooms";
const ID_SECTION_MEDIAS = "medias";
const ID_SECTION_EQUIPMENTS = "equipments";
const ID_SECTION_LOCALISATION = "localisation";
const ID_SECTION_SUPPLEMENTS = "supplements";
const ID_SECTION_REVIEWS = "reviews";

// #region sections
/**
 * @type {Record<SectionValue, {id: SectionValue}>}
 */
const SECTIONS = {
  [ID_SECTION_DESCRIPTION]: {
    id: ID_SECTION_DESCRIPTION,
  },
  [ID_SECTION_ROOMS]: {
    id: ID_SECTION_ROOMS,
  },
  [ID_SECTION_MEDIAS]: {
    id: ID_SECTION_MEDIAS,
  },
  [ID_SECTION_EQUIPMENTS]: {
    id: ID_SECTION_EQUIPMENTS,
  },
  [ID_SECTION_LOCALISATION]: {
    id: ID_SECTION_LOCALISATION,
  },
  [ID_SECTION_SUPPLEMENTS]: {
    id: ID_SECTION_SUPPLEMENTS,
  },
  [ID_SECTION_REVIEWS]: {
    id: ID_SECTION_REVIEWS,
  },
};

const SECTIONS_IDS = Object.keys(SECTIONS);

// #region component
/**
 * @typedef {object} Props
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Hotel").Hotel>>} swrResponseHotel
 */
/**
 * @param {Props} props
 */
export function HotelView({ swrResponseHotel }) {
  const intl = useIntl();

  const translate = useTranslate();

  const { locale, defaultLocale } = useLocale();

  const {
    isOpen: isOpenModalBookingSuccess,
    onOpen: onOpenModalBookingSuccess,
    onClose: onCloseModalBookingSuccess,
  } = useDisclosure();

  const {
    isOpen: isOpenBottomBar,
    onOpen: onOpenBottomBar,
    onClose: onCloseBottomBar,
  } = useDisclosure();

  const initialCheckin = useRouterString("checkin");
  const initialCheckout = useRouterString("checkout");
  const initialAdults = useRouterNumber("adults");
  const typeOfDuration =
    useMemo(() => {
      if (initialCheckin && initialCheckout) {
        const today = dayjs().startOf("day");
        const checkinDate = dayjs(initialCheckin);
        const checkoutDate = dayjs(initialCheckout);
        if (
          checkinDate.isValid() &&
          checkoutDate.isValid() &&
          (checkinDate.isSame(today) || checkinDate.isAfter(today)) &&
          (checkoutDate.isSame(checkinDate) ||
            checkoutDate.isAfter(checkinDate))
        ) {
          if (checkoutDate.diff(initialCheckin, "days") >= 5) {
            return "week";
          }
        }
      }
      return "night";
    }, [initialCheckin, initialCheckout]) ?? {};
  const adults = initialAdults || 1;
  const children = useRouterNumber("children");
  const babies = useRouterNumber("babies");
  const pets = useRouterNumber("pets");

  const [highlightedSection, setHighlightedSection] = useState(
    /** @type {string | null} */ (null),
  );

  const [isSticky, setIsSticky] = useState(false);

  const tabsContainerRef = useRef(/** @type {HTMLDivElement | null} */ (null));

  const hotel = swrResponseHotel.data?.data;

  const { createHotelMetrics } = useCreateHotelMetricsEvent();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted && hotel?.id) {
      createHotelMetrics({
        hotelId: hotel?.id,
        eventType: HOTEL_METRICS_EVENT_TYPE_VALUE_VIEW,
      });
      setMounted(true);
    }
  }, [createHotelMetrics, hotel?.id, mounted]);

  const departmentSlug = hotel?.address?.department
    ? getDepartmentSlug({
        department: hotel.address?.department,
        locale,
        defaultLocale,
      })
    : null;

  const { headerHeight } = useHeader();

  const [isHeightSmallerThan730] = useMediaQuery("(max-height: 730px)");

  const indoorAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const interiorEquipmentsAmenities = [];
    const amenities =
      hotel?.count_rooms === 1 ? hotel.rooms?.[0]?.amenities : hotel?.amenities;
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_INSIDE) {
        interiorEquipmentsAmenities.push(amenity);
      }
    });
    return interiorEquipmentsAmenities;
  }, [hotel?.amenities, hotel?.count_rooms, hotel?.rooms]);

  const outdoorAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const exteriorEquipmentsAmenities = [];
    const amenities =
      hotel?.count_rooms === 1 ? hotel.rooms?.[0]?.amenities : hotel?.amenities;
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_OUTSIDE) {
        exteriorEquipmentsAmenities.push(amenity);
      }
    });
    return exteriorEquipmentsAmenities;
  }, [hotel?.amenities, hotel?.count_rooms, hotel?.rooms]);

  // High tech amenities in only in rooms
  const highTechAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const highTechAmenities = [];
    const amenities =
      hotel?.count_rooms === 1 ? hotel?.rooms?.[0]?.amenities : [];
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_HIGHTECH) {
        highTechAmenities.push(amenity);
      }
    });
    return highTechAmenities;
  }, [hotel?.count_rooms, hotel?.rooms]);

  // Baby amenities in only in rooms
  const babyAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const babyAmenities = [];
    const amenities =
      hotel?.count_rooms === 1 ? hotel?.rooms?.[0]?.amenities : [];
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_BABY) {
        babyAmenities.push(amenity);
      }
    });
    return babyAmenities;
  }, [hotel?.count_rooms, hotel?.rooms]);

  // Comfort amenities in only in rooms
  const comfortAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const comfortAmenities = [];
    const amenities =
      hotel?.count_rooms === 1 ? hotel?.rooms?.[0]?.amenities : [];
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_COMFORT) {
        comfortAmenities.push(amenity);
      }
    });
    return comfortAmenities;
  }, [hotel?.count_rooms, hotel?.rooms]);

  // Hobby amenities in only in rooms
  const hobbyAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const hobbyAmenities = [];
    const amenities =
      hotel?.count_rooms === 1 ? hotel?.rooms?.[0]?.amenities : [];
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_HOBBY) {
        hobbyAmenities.push(amenity);
      }
    });
    return hobbyAmenities;
  }, [hotel?.count_rooms, hotel?.rooms]);

  // Partner amenities in only in rooms
  const partnerAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const partnerAmenities = [];
    const amenities =
      hotel?.count_rooms === 1 ? hotel?.rooms?.[0]?.amenities : [];
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_PARTNER) {
        partnerAmenities.push(amenity);
      }
    });
    return partnerAmenities;
  }, [hotel?.count_rooms, hotel?.rooms]);

  // Security amenities in only in rooms
  const securityAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const securityAmenities = [];
    const amenities =
      hotel?.count_rooms === 1 ? hotel?.rooms?.[0]?.amenities : [];
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_SECURITY) {
        securityAmenities.push(amenity);
      }
    });
    return securityAmenities;
  }, [hotel?.count_rooms, hotel?.rooms]);

  const proximityAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const proximityAmenities = [];
    const amenities = hotel?.amenities;
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_PROXIMITY) {
        proximityAmenities.push(amenity);
      }
    });
    return proximityAmenities;
  }, [hotel?.amenities]);

  const servicesAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const servicesAmenities = [];
    const amenities = hotel?.amenities;
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_SERVICE) {
        servicesAmenities.push(amenity);
      }
    });
    return servicesAmenities;
  }, [hotel?.amenities]);

  const labelAndThemeAmenities = useMemo(() => {
    const roomsAmenities = [];

    hotel?.rooms?.forEach((room) => {
      roomsAmenities.push(
        (room.amenities?.length ?? 0) > 0 ? room.amenities : [],
      );
    });

    // Remove the duplicate amenities by checkin the amenity_id
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const filteredRoomsAmenities = roomsAmenities
      .flat()
      .filter(
        (amenity, index, self) =>
          index === self.findIndex((t) => t.amenity_id === amenity.amenity_id),
      );

    return (
      filteredRoomsAmenities?.filter((amenity) =>
        [
          AMENITIES_CATEGORY_VALUE_LABEL,
          AMENITIES_CATEGORY_VALUE_THEME,
        ].includes(amenity.category),
      ) ?? []
    );
  }, [hotel?.rooms]);

  const parkingAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const parkingAmenities = [];
    const amenities = hotel?.amenities;
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_PARKING) {
        parkingAmenities.push(amenity);
      }
    });
    return parkingAmenities;
  }, [hotel?.amenities]);

  const sustainableAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const sustainableAmenities = [];
    const amenities = hotel?.amenities;
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_SUSTAINABLE) {
        sustainableAmenities.push(amenity);
      }
    });
    return sustainableAmenities;
  }, [hotel?.amenities]);

  const chargingStationAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const chargingStationAmenities = [];
    const amenities = hotel?.amenities;
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_CHARGING_STATION) {
        chargingStationAmenities.push(amenity);
      }
    });
    return chargingStationAmenities;
  }, [hotel?.amenities]);

  const accessibilityAmenities = useMemo(() => {
    /** @type {import("@raiden/library-ui/types/Room").RoomAmenity[]} */
    const accessibilityAmenities = [];
    const amenities =
      hotel?.count_rooms === 1 ? hotel.rooms?.[0]?.amenities : hotel?.amenities;
    amenities?.forEach((amenity) => {
      if (amenity.category === AMENITIES_CATEGORY_VALUE_ACCESSIBILITY) {
        accessibilityAmenities.push(amenity);
      }
    });
    return accessibilityAmenities;
  }, [hotel?.amenities, hotel?.count_rooms, hotel?.rooms]);

  useEffect(() => {
    const handleScroll = () => {
      // Determine if the tabs are stuck
      if (tabsContainerRef.current) {
        if (tabsContainerRef.current) {
          const rect = tabsContainerRef.current.getBoundingClientRect();
          setIsSticky(rect.top <= headerHeight); // Adjust as necessary
        }
      }

      // Determine the section that is currently visible
      const sections = [];
      Object.values(SECTIONS_IDS).forEach((sectionId) => {
        const element = document.getElementById(sectionId);

        if (element) {
          const rect = element.getBoundingClientRect();
          sections.push({
            id: sectionId,
            top: rect.top,
            bottom: rect.bottom,
          });
        }
      });

      const offsetTop = headerHeight + 40;

      // Filter sections that are currently visible
      const visibleSections = sections.filter(
        (section) =>
          section.top < window.innerHeight && section.bottom > offsetTop,
      );

      // Determine the visible section closest to the top
      if (visibleSections.length > 0) {
        const visibleSection = visibleSections.reduce(
          (prev, current) =>
            Math.abs(current.top - offsetTop) < Math.abs(prev.top - offsetTop)
              ? current
              : prev,
          visibleSections[0], // Provide the first visible section as the initial value
        );
        setHighlightedSection(visibleSection.id);
      } else {
        setHighlightedSection(null);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headerHeight]);

  const virtualVisits = useMemo(() => {
    return (
      hotel?.medias?.filter(
        (media) => media.type === HOTELS_MEDIA_TYPE_VALUE_VIRTUAL_VISIT,
      ) ?? []
    );
  }, [hotel?.medias]);

  const videos = useMemo(() => {
    return (
      hotel?.medias?.filter(
        (media) => media.type === HOTELS_MEDIA_TYPE_VALUE_VIDEO,
      ) ?? []
    );
  }, [hotel?.medias]);

  const isBookableOnline = Boolean(
    hotel?.is_bookable?.on_demand || hotel?.is_bookable?.system,
  );

  const isPlanExpert = hotel?.plan ? plansIsAliasExpert(hotel?.plan) : false;

  const { setCartInfo, mutate } = useCart();

  // #region handleCartCreated
  const handleCartCreated = useCallback(
    /** @type {import("../../CartBookings/Create/Handler").CartBookingsCreateHandlerOnCartCreated} */
    ({ response }) => {
      setCartInfo({
        id: response.data.id,
        accessToken: response.data.access_token,
      });
    },
    [setCartInfo],
  );

  // #region handleBookingCreated
  const handleBookingCreated = useCallback(() => {
    mutate();
  }, [mutate]);

  // #region handleSuccess
  const handleSuccess = useCallback(() => {
    onOpenModalBookingSuccess();
  }, [onOpenModalBookingSuccess]);

  const hotelTitle = useMemo(() => {
    const defaultTitle = intl.formatMessage({
      defaultMessage: "Détail d'un hébergement",
    });

    if (hotel?.title) {
      return translate(hotel?.title);
    }

    return hotel?.hlo ?? defaultTitle;
  }, [hotel?.hlo, hotel?.title, intl, translate]);

  // #region return
  return (
    <>
      <PageLayout
        header={
          <PageHeaderPublic
            title={hotelTitle}
            breadCrumbItems={useMemo(() => {
              /** @type {import("../../../components/BreadcrumbsPublic").BreadCrumbItemPublic[]} */
              const items = [
                {
                  title: intl.formatMessage({
                    defaultMessage: "Accueil",
                  }),
                  url: generateFrontPath({
                    id: "@front.home",
                  }),
                },
                {
                  title: intl.formatMessage({
                    defaultMessage: "Hébergements de vacances",
                  }),
                  url: generateFrontPath({ id: "@front.search" }),
                },
              ];
              if (hotel?.seo) {
                if (hotel.seo?.parent_region) {
                  items.push({
                    title: hotel.seo.parent_region.title,
                    url: generateFrontPath({
                      id: "@front.thematics.view",
                      parameters: {
                        campaignSlug: `${hotel?.seo?.parent_region?.id}-${hotel?.seo?.parent_region?.slug}`,
                      },
                    }),
                  });
                }
                if (hotel.seo?.parent_department) {
                  items.push({
                    title: hotel.seo.parent_department.title,
                    url: generateFrontPath({
                      id: "@front.thematics.view",
                      parameters: {
                        campaignSlug: `${hotel?.seo?.parent_department?.id}-${hotel?.seo?.parent_department?.slug}`,
                      },
                    }),
                  });
                }
                if (hotel.seo?.parent_city) {
                  items.push({
                    title: hotel.seo.parent_city.title,
                    url: generateFrontPath({
                      id: "@front.thematics.view",
                      parameters: {
                        campaignSlug: `${hotel?.seo?.parent_city?.id}-${hotel?.seo?.parent_city?.slug}`,
                      },
                    }),
                  });
                }
              }
              if (hotel?.title) {
                const title = translate(hotel.title);
                if (title) {
                  items.push({
                    title,
                  });
                }
              }
              return items;
            }, [hotel?.title, hotel?.seo, intl, translate])}
          />
        }>
        <Box my="32px">
          <CenteredContent>
            <DataHandler swrResponse={swrResponseHotel}>
              {({ data: hotel }) => (
                <ApiErrorProvider>
                  <CartBookingsCreateHandler
                    onCartCreated={handleCartCreated}
                    onBookingCreated={handleBookingCreated}
                    onSuccess={handleSuccess}
                    hotel={hotel}
                    initialRoomId={hotel.rooms?.[0]?.id ?? 0}
                    initialAdults={adults}
                    initialChildren={children}
                    initialBabies={babies}
                    initialPets={pets}
                    initialCheckin={initialCheckin}
                    initialCheckout={initialCheckout}>
                    {({ form, isLoading, submit }) => (
                      <>
                        <Stack spacing="24px">
                          <Card>
                            <Stack spacing="24px" py="16px">
                              <HStack alignItems="flex-start" px="16px">
                                <Box flexGrow={1}>
                                  <Wrap spacing="8px">
                                    <HotelTags
                                      type={hotel.type}
                                      highlight={hotel.highlight}
                                      isNew={hotel.new}
                                      labelRanking={
                                        hotel.ranking_infos?.label ?? null
                                      }
                                      isClevRoom={hotel.is_clev_room}
                                    />

                                    {(hotel.legal_capacity ?? 0) > 0 && (
                                      <Text color="gray.500">
                                        <FormattedMessage
                                          defaultMessage="Labellisé {capacity, plural, one {# personne} other {# personnes}}"
                                          values={{
                                            capacity: hotel.legal_capacity,
                                          }}
                                        />
                                      </Text>
                                    )}

                                    {hotel.address && (
                                      <Text color="gray.500">
                                        <FormattedMessage
                                          defaultMessage="À <linkBlack>{city}</linkBlack> · {hasDepartment, select, true {<link>{department}</link>} other {}}"
                                          values={{
                                            city: hotel.address.city,
                                            hasDepartment: Boolean(
                                              hotel.address?.department,
                                            ),
                                            department:
                                              hotel.address.department?.name,
                                            linkBlack: (chunks) => (
                                              <Href
                                                href={generateFrontPath({
                                                  id: "@front.search",
                                                  query: {
                                                    location: {
                                                      map: {
                                                        latitude:
                                                          hotel.localisation
                                                            ?.latitude,
                                                        longitude:
                                                          hotel.localisation
                                                            ?.longitude,
                                                        radius: 999,
                                                      },
                                                    },
                                                  },
                                                })}>
                                                <Text as="span" color="#002538">
                                                  {chunks}
                                                </Text>
                                              </Href>
                                            ),
                                            link: (chunks) => (
                                              <Href
                                                href={generateFrontPath({
                                                  id: "@front.departments.view",
                                                  parameters: {
                                                    departmentSlug,
                                                  },
                                                })}>
                                                {chunks}
                                              </Href>
                                            ),
                                          }}
                                        />
                                      </Text>
                                    )}
                                  </Wrap>

                                  <HotelViewQuickSummary hotel={hotel} />
                                </Box>

                                <Stack>
                                  <Share
                                    hotelId={hotel.id}
                                    title={
                                      hotel.title
                                        ? translate(hotel.title)
                                        : undefined
                                    }
                                    text={intl.formatMessage({
                                      defaultMessage:
                                        "Cet hébergement pourrait vous intéresser.",
                                    })}
                                    url={generateFrontUrl({
                                      id: "@front.hotels.view",
                                      parameters: {
                                        hotelSlug: getHotelSlug({
                                          hotel: hotel,
                                          locale,
                                          defaultLocale,
                                        }),
                                      },
                                    })}>
                                    {({ share }) => (
                                      <Button
                                        onClick={share}
                                        leftIcon={<Icon icon="ms_share" />}
                                        variant="outline"
                                        size="sm"
                                        fontWeight={400}>
                                        <FormattedMessage defaultMessage="Partager" />
                                      </Button>
                                    )}
                                  </Share>

                                  {hotel.review_infos && (
                                    <Flex justify="flex-end">
                                      <HotelReviewSummary
                                        reviewInfos={hotel.review_infos}
                                      />
                                    </Flex>
                                  )}
                                </Stack>
                              </HStack>

                              <Box
                                px={{ base: 0, lg: "16px" }}
                                position="relative"
                                role="group">
                                <HotelViewPictures
                                  roomPictures={hotel.pictures}
                                />

                                <Image
                                  src={generateFrontPath({
                                    id: "@front.internal-assets",
                                    parameters: {
                                      filePath: "logo-label.svg",
                                    },
                                    includeBasePath: true,
                                  })}
                                  _groupHover={{
                                    opacity: 0.5,
                                  }}
                                  width="64px"
                                  transition="opacity 0.25s"
                                  position="absolute"
                                  top="8px"
                                  left="24px"
                                  alt={intl.formatMessage({
                                    defaultMessage: "Label Clévacances",
                                  })}
                                />
                              </Box>
                            </Stack>
                          </Card>

                          <Flex
                            direction="row"
                            alignItems="flex-start"
                            zIndex={1}>
                            <Box flexGrow={1} maxWidth="full">
                              <Card>
                                <Stack spacing="24px" pt="8px" pb="16px">
                                  <Box
                                    ref={tabsContainerRef}
                                    position="sticky"
                                    top={`${headerHeight}px`}
                                    zIndex={1}
                                    backgroundColor="#FFF"
                                    borderTopRadius={isSticky ? "0" : "20px"}
                                    borderBottomWidth="1px"
                                    borderBottomColor={
                                      isSticky ? undefined : "transparent"
                                    }>
                                    <HStack
                                      spacing="8px"
                                      px="16px"
                                      py="8px"
                                      overflowX="auto">
                                      <ButtonSection
                                        sectionId={ID_SECTION_DESCRIPTION}
                                        title={intl.formatMessage({
                                          defaultMessage: "Description",
                                        })}
                                        highlightedSection={highlightedSection}
                                      />

                                      {(virtualVisits?.length > 0 ||
                                        videos?.length > 0) && (
                                        <ButtonSection
                                          sectionId={ID_SECTION_MEDIAS}
                                          title={intl.formatMessage({
                                            defaultMessage: "Vidéo",
                                          })}
                                          highlightedSection={
                                            highlightedSection
                                          }
                                        />
                                      )}

                                      {hotel.count_rooms === 1 &&
                                        hotel.rooms?.[0]?.compositions && (
                                          <ButtonSection
                                            sectionId={ID_SECTION_ROOMS}
                                            title={intl.formatMessage({
                                              defaultMessage: "Les pièces",
                                            })}
                                            highlightedSection={
                                              highlightedSection
                                            }
                                          />
                                        )}

                                      {hotel.count_rooms > 1 && hotel.rooms && (
                                        <ButtonSection
                                          sectionId={ID_SECTION_ROOMS}
                                          title={intl.formatMessage({
                                            defaultMessage: "Les chambres",
                                          })}
                                          highlightedSection={
                                            highlightedSection
                                          }
                                        />
                                      )}

                                      {(indoorAmenities ||
                                        outdoorAmenities) && (
                                        <ButtonSection
                                          sectionId={ID_SECTION_EQUIPMENTS}
                                          title={intl.formatMessage({
                                            defaultMessage: "Équipements",
                                          })}
                                          highlightedSection={
                                            highlightedSection
                                          }
                                        />
                                      )}

                                      {(hotel.localisation?.latitude !==
                                        undefined ||
                                        hotel.localisation?.longitude) && (
                                        <ButtonSection
                                          sectionId={ID_SECTION_LOCALISATION}
                                          title={intl.formatMessage({
                                            defaultMessage: "Localisation",
                                          })}
                                          highlightedSection={
                                            highlightedSection
                                          }
                                        />
                                      )}

                                      <ButtonSection
                                        sectionId={ID_SECTION_REVIEWS}
                                        title={intl.formatMessage({
                                          defaultMessage: "Avis",
                                        })}
                                        highlightedSection={highlightedSection}
                                      />
                                    </HStack>
                                  </Box>

                                  {hotel.top_amenities &&
                                    hotel.top_amenities.length > 0 && (
                                      <Wrap spacing="16px" px="16px">
                                        {hotel.top_amenities.map((amenity) => (
                                          <HotelViewTopAmenity
                                            roomAmenity={amenity}
                                            key={amenity.id}
                                          />
                                        ))}
                                      </Wrap>
                                    )}

                                  <HotelViewHotelInfo hotel={hotel} />

                                  <Box px="1rem">
                                    <HotelViewRoomDetail
                                      hotel={hotel}
                                      room={
                                        hotel.count_rooms === 1
                                          ? hotel.rooms?.[0] ?? null
                                          : null
                                      }
                                    />
                                  </Box>

                                  {labelAndThemeAmenities?.length > 0 && (
                                    <HotelViewAmenitiesTabs
                                      roomAmenities={labelAndThemeAmenities}
                                    />
                                  )}

                                  <Stack
                                    spacing="24px"
                                    id={ID_SECTION_DESCRIPTION}
                                    scrollMarginTop={`${headerHeight + 48}px`}
                                    px="16px">
                                    <HotelViewDescriptions
                                      hotel={hotel}
                                      {...(hotel.count_rooms === 1 && {
                                        room: hotel.rooms?.[0],
                                      })}
                                    />
                                  </Stack>

                                  {(virtualVisits?.length > 0 ||
                                    videos?.length > 0) && (
                                    <Box
                                      id={ID_SECTION_MEDIAS}
                                      scrollMarginTop={`${headerHeight + 48}px`}
                                      px="16px">
                                      <HotelViewMedias
                                        virtualVisits={virtualVisits}
                                        videos={videos}
                                      />
                                    </Box>
                                  )}

                                  {hotel.count_rooms === 1 &&
                                    hotel.rooms?.[0]?.compositions && (
                                      <Box
                                        id={ID_SECTION_ROOMS}
                                        scrollMarginTop={`${
                                          headerHeight + 48
                                        }px`}
                                        px="16px">
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage: "Les pièces",
                                          })}>
                                          <HotelViewRoomCompositions
                                            roomCompositions={
                                              hotel.rooms[0].compositions
                                            }
                                          />
                                        </HotelViewSection>
                                      </Box>
                                    )}

                                  {hotel.compositions &&
                                    hotel.compositions.length > 0 && (
                                      <Box
                                        id={ID_SECTION_ROOMS}
                                        scrollMarginTop={`${
                                          headerHeight + 48
                                        }px`}
                                        px="16px">
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage:
                                              "Les pièces partagées",
                                          })}>
                                          <HotelViewRoomCompositions
                                            roomCompositions={
                                              hotel.compositions
                                            }
                                          />
                                        </HotelViewSection>
                                      </Box>
                                    )}

                                  {hotel.count_rooms > 1 && hotel.rooms && (
                                    <Box
                                      id={ID_SECTION_ROOMS}
                                      scrollMarginTop={`${headerHeight + 48}px`}
                                      px="16px">
                                      <HotelViewSection
                                        title={intl.formatMessage(
                                          {
                                            defaultMessage:
                                              "{hotelType, select, guest_house {Les chambres} other {Les gîtes}}",
                                          },
                                          {
                                            hotelType: hotel.type,
                                          },
                                        )}>
                                        <HotelViewRooms
                                          rooms={hotel.rooms}
                                          typeOfDuration={typeOfDuration}
                                          form={form}
                                          onOpenBottomBar={onOpenBottomBar}
                                          hotelType={hotel.type}
                                        />
                                      </HotelViewSection>
                                    </Box>
                                  )}

                                  {hotel.count_rooms === 1 &&
                                    hotel.rooms?.[0]?.services !== undefined &&
                                    hotel.rooms[0].services.length > 0 && (
                                      <Box
                                        id={ID_SECTION_SUPPLEMENTS}
                                        scrollMarginTop={`${
                                          headerHeight + 48
                                        }px`}
                                        px="16px">
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage: "Suppléments",
                                          })}>
                                          <HotelViewRoomServices
                                            roomServices={
                                              hotel.rooms[0].services
                                            }
                                          />
                                        </HotelViewSection>
                                      </Box>
                                    )}

                                  {(indoorAmenities.length > 0 ||
                                    outdoorAmenities.length > 0 ||
                                    servicesAmenities.length > 0 ||
                                    parkingAmenities.length > 0 ||
                                    sustainableAmenities.length > 0 ||
                                    chargingStationAmenities.length > 0 ||
                                    highTechAmenities.length > 0 ||
                                    babyAmenities.length > 0 ||
                                    comfortAmenities.length > 0 ||
                                    hobbyAmenities.length > 0 ||
                                    partnerAmenities.length > 0 ||
                                    securityAmenities.length > 0) && (
                                    <Stack
                                      spacing="24px"
                                      id={ID_SECTION_EQUIPMENTS}
                                      scrollMarginTop={`${headerHeight + 48}px`}
                                      px="16px">
                                      {indoorAmenities.length > 0 && (
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage:
                                              "Équipements intérieurs",
                                          })}>
                                          <HotelViewAmenities
                                            roomAmenities={indoorAmenities}
                                          />
                                        </HotelViewSection>
                                      )}

                                      {outdoorAmenities.length > 0 && (
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage:
                                              "Équipements extérieurs",
                                          })}>
                                          <HotelViewAmenities
                                            roomAmenities={outdoorAmenities}
                                          />
                                        </HotelViewSection>
                                      )}

                                      {accessibilityAmenities.length > 0 && (
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage: "Accessibilité",
                                          })}>
                                          <HotelViewAmenities
                                            roomAmenities={
                                              accessibilityAmenities
                                            }
                                          />
                                        </HotelViewSection>
                                      )}

                                      {parkingAmenities.length > 0 && (
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage: "Parkings",
                                          })}>
                                          <HotelViewAmenities
                                            roomAmenities={parkingAmenities}
                                          />
                                        </HotelViewSection>
                                      )}

                                      {sustainableAmenities.length > 0 && (
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage:
                                              "Équipements durables",
                                          })}>
                                          <HotelViewAmenities
                                            roomAmenities={sustainableAmenities}
                                          />
                                        </HotelViewSection>
                                      )}

                                      {chargingStationAmenities.length > 0 && (
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage: "Borne de recharge",
                                          })}>
                                          <HotelViewAmenities
                                            roomAmenities={
                                              chargingStationAmenities
                                            }
                                          />
                                        </HotelViewSection>
                                      )}

                                      {hotel.count_rooms === 1 && (
                                        <>
                                          {highTechAmenities.length > 0 && (
                                            <HotelViewSection
                                              title={intl.formatMessage({
                                                defaultMessage: "High-tech",
                                              })}>
                                              <HotelViewAmenities
                                                roomAmenities={
                                                  highTechAmenities
                                                }
                                              />
                                            </HotelViewSection>
                                          )}

                                          {babyAmenities.length > 0 && (
                                            <HotelViewSection
                                              title={intl.formatMessage({
                                                defaultMessage:
                                                  "Équipements bébé",
                                              })}>
                                              <HotelViewAmenities
                                                roomAmenities={babyAmenities}
                                              />
                                            </HotelViewSection>
                                          )}

                                          {comfortAmenities.length > 0 && (
                                            <HotelViewSection
                                              title={intl.formatMessage({
                                                defaultMessage: "Bien être",
                                              })}>
                                              <HotelViewAmenities
                                                roomAmenities={comfortAmenities}
                                              />
                                            </HotelViewSection>
                                          )}

                                          {hobbyAmenities.length > 0 && (
                                            <HotelViewSection
                                              title={intl.formatMessage({
                                                defaultMessage: "Loisirs",
                                              })}>
                                              <HotelViewAmenities
                                                roomAmenities={hobbyAmenities}
                                              />
                                            </HotelViewSection>
                                          )}

                                          {partnerAmenities.length > 0 && (
                                            <HotelViewSection
                                              title={intl.formatMessage({
                                                defaultMessage: "Partenaires",
                                              })}>
                                              <HotelViewAmenities
                                                roomAmenities={partnerAmenities}
                                              />
                                            </HotelViewSection>
                                          )}

                                          {securityAmenities.length > 0 && (
                                            <HotelViewSection
                                              title={intl.formatMessage({
                                                defaultMessage: "Sécurité",
                                              })}>
                                              <HotelViewAmenities
                                                roomAmenities={
                                                  securityAmenities
                                                }
                                              />
                                            </HotelViewSection>
                                          )}
                                        </>
                                      )}
                                    </Stack>
                                  )}

                                  {hotel.translations?.proximity && (
                                    <Box
                                      id={ID_SECTION_LOCALISATION}
                                      scrollMarginTop={`${headerHeight + 48}px`}
                                      px="16px">
                                      <HotelViewSection
                                        title={intl.formatMessage({
                                          defaultMessage: "À proximité",
                                        })}>
                                        <ExpandableContainer maxHeight={24 * 4}>
                                          <Text whiteSpace="pre-wrap">
                                            {translate(
                                              hotel.translations.proximity,
                                            )}
                                          </Text>
                                        </ExpandableContainer>
                                      </HotelViewSection>
                                    </Box>
                                  )}

                                  {hotel.translations?.proximity && (
                                    <Box
                                      id={ID_SECTION_LOCALISATION}
                                      scrollMarginTop={`${headerHeight + 48}px`}
                                      px="16px">
                                      <HotelViewSection
                                        title={intl.formatMessage({
                                          defaultMessage: "Plan d'accès",
                                        })}>
                                        <ExpandableContainer maxHeight={24 * 4}>
                                          <Text whiteSpace="pre-wrap">
                                            {translate(
                                              hotel.translations.access_plan,
                                            )}
                                          </Text>
                                        </ExpandableContainer>
                                      </HotelViewSection>
                                    </Box>
                                  )}

                                  {hotel.localisation !== undefined &&
                                    hotel.localisation.latitude !== null &&
                                    hotel.localisation.longitude !== null && (
                                      <Box
                                        id={ID_SECTION_LOCALISATION}
                                        scrollMarginTop={`${
                                          headerHeight + 48
                                        }px`}
                                        px="16px">
                                        <HotelViewSection
                                          title={intl.formatMessage({
                                            defaultMessage: "Localisation",
                                          })}>
                                          <HotelViewMapDynamic
                                            latitude={
                                              hotel.localisation.latitude
                                            }
                                            longitude={
                                              hotel.localisation.longitude
                                            }
                                          />
                                        </HotelViewSection>
                                      </Box>
                                    )}

                                  {proximityAmenities.length > 0 && (
                                    <Box px="16px">
                                      <HotelViewSection
                                        title={intl.formatMessage({
                                          defaultMessage:
                                            "À découvrir dans les environs",
                                        })}>
                                        <HotelViewProximityAmenities
                                          amenities={proximityAmenities}
                                        />
                                      </HotelViewSection>
                                    </Box>
                                  )}

                                  {servicesAmenities.length > 0 && (
                                    <Box px="16px">
                                      <HotelViewSection
                                        title={intl.formatMessage({
                                          defaultMessage: "Services",
                                        })}>
                                        <HotelViewProximityAmenities
                                          amenities={servicesAmenities}
                                        />
                                      </HotelViewSection>
                                    </Box>
                                  )}

                                  {hotel.main_contact && (
                                    <Box px="16px">
                                      <HotelViewSection
                                        title={intl.formatMessage({
                                          defaultMessage: "Propriétaire",
                                        })}>
                                        <HotelViewCustomer
                                          customer={hotel.main_contact}
                                          withPublicInfo={true}
                                          withWebsite={!isPlanExpert}
                                          withWebsiteInDescription={true}
                                          hotelId={hotel.id}
                                        />
                                      </HotelViewSection>
                                    </Box>
                                  )}

                                  {(hotel.review_infos?.count ?? 0) > 0 && (
                                    <Box
                                      id={ID_SECTION_REVIEWS}
                                      scrollMarginTop={`${headerHeight + 48}px`}
                                      px="16px">
                                      <HotelViewSection
                                        title={intl.formatMessage({
                                          defaultMessage:
                                            "Les avis de voyageurs",
                                        })}>
                                        <HotelViewReviews
                                          reviewInfos={hotel.review_infos}
                                          hotelId={hotel.id}
                                        />
                                      </HotelViewSection>
                                    </Box>
                                  )}
                                </Stack>
                              </Card>
                            </Box>

                            <Box
                              alignSelf="flex-start"
                              position={
                                isHeightSmallerThan730 ? "unset" : "sticky"
                              }
                              ml="16px"
                              minW="300px"
                              display={{ base: "none", lg: "block" }}
                              top={
                                isHeightSmallerThan730
                                  ? undefined
                                  : `${headerHeight + 16}px`
                              }
                              zIndex={1}>
                              <CartBookingSidebar
                                form={form}
                                submit={submit}
                                hotel={hotel}
                                isPlanExpert={isPlanExpert}
                                isBookableOnline={isBookableOnline}
                                isLoading={isLoading}
                                initialCheckin={initialCheckin}
                                initialCheckout={initialCheckout}
                                isOpenBottomBar={isOpenBottomBar}
                              />
                            </Box>
                          </Flex>
                        </Stack>

                        {hotel.rooms?.[0]?.id !== undefined &&
                          hotel.customer !== undefined && (
                            <Portal>
                              <CartBookingBottomBar
                                form={form}
                                submit={submit}
                                hotel={hotel}
                                isBookableOnline={isBookableOnline}
                                isPlanExpert={isPlanExpert}
                                isLoading={isLoading}
                                isOpen={isOpenBottomBar}
                                onOpen={onOpenBottomBar}
                                onClose={onCloseBottomBar}
                                initialCheckin={initialCheckin}
                                initialCheckout={initialCheckout}
                              />
                            </Portal>
                          )}
                      </>
                    )}
                  </CartBookingsCreateHandler>
                </ApiErrorProvider>
              )}
            </DataHandler>
          </CenteredContent>
        </Box>
      </PageLayout>

      <ModalBookingSuccess
        isOpen={isOpenModalBookingSuccess}
        onClose={onCloseModalBookingSuccess}
      />
    </>
  );
}
