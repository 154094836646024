import { apiGetErrorStatus } from "@raiden/library-ui/helpers/api";
import { getPictureForPosition } from "@raiden/library-ui/helpers/hotels/getPictureForPosition";
import {
  getString,
  useRouterString,
} from "@raiden/library-ui/hooks/router/useRouterString";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import { serverApiFetcher } from "@raiden/library-ui/hooks/useApiFetcher";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import generatePampaUri from "@raiden/library-ui/libraries/utils/generatePampaUri";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import Seo from "../../components/Seo";
import { HotelView } from "../../containers/Hotels/View";
import { PageErrorHandlerPublic } from "../../containers/PageErrorHandlerPublic";
import { getIdFromSlug } from "../../helpers/getIdFromSlug";
import { frontBaseUri } from "@raiden/library-ui/constants/routers/front";
import {
  hotelsTypeMessage,
  HOTELS_TYPE_VALUE_COTTAGE,
} from "@raiden/library-ui/constants/hotels";
import { getHotelSlug } from "@raiden/library-ui/helpers/hotels/getSlug";
import useLocale from "@raiden/library-ui/hooks/useLocale";

// #region getUrlHotel
/**
 * @param {object} params
 * @param {number} params.hotelId
 */
function getUrlHotel({ hotelId }) {
  return generateApiUrl({
    id: "@hotels.view",
    parameters: {
      hotelId,
    },
    query: {
      fields: [
        "hotels.address",
        "hotels.amenities",
        "hotels.compositions",
        "hotels.customer",
        "hotels.main_contact",
        "hotels.is_bookable",
        "hotels.localisation",
        "hotels.medias",
        "hotels.payment_modes",
        "hotels.pets",
        "hotels.pictures",
        "hotels.ranking_infos",
        "hotels.review_infos",
        "hotels.rooms",
        "hotels.top_amenities",
        "hotels.translations",
        "rooms.amenities",
        "rooms.composition_infos",
        "rooms.compositions",
        "rooms.compositions.amenities",
        "rooms.compositions.pictures",
        "rooms.last_label_ranking",
        "rooms.pictures",
        "rooms.services",
        "users.customers.avatar",
        "users.customers.contacts",
        "hotels.plan",
        "hotels.seo",
      ],
    },
  });
}

// #region getStatus
/**
 * Resolves the status code of the page based on the response and error.
 * @param {object} params
 * @param {import("@raiden/library-ui/types/Api/ApiError").ApiError} [params.error]
 * @returns {number | undefined}
 */
function getStatus({ error }) {
  if (error) {
    return apiGetErrorStatus({ error: error });
  }
  return undefined;
}

// #region component
/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/Hotel").Hotel>} [hotelResponse]
 * @property {number} [serverStatus]
 */
/**
 * @param {Props} props
 */
function Page({ hotelResponse, serverStatus }) {
  const intl = useIntl();
  const { locale, defaultLocale } = useLocale();
  const translate = useTranslate();

  const hotelSlug = useRouterString("hotelSlug");
  const hotelId = getIdFromSlug(hotelSlug);

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Hotel").Hotel>} */
  const { swrResponse: swrResponseHotel } = useApi(
    !hotelResponse ? (hotelId ? getUrlHotel({ hotelId }) : null) : null,
    {
      swrConfig: { fallbackData: hotelResponse },
    },
  );

  const hotel = swrResponseHotel?.data?.data;

  const title = useMemo(() => {
    const defaultTitle = intl.formatMessage({ defaultMessage: "Hébergement" });

    if (hotel?.title) {
      let title = "";

      title += translate(hotel?.title);

      if (hotel?.type) {
        if (hotel.type === HOTELS_TYPE_VALUE_COTTAGE) {
          title += ` - ${intl.formatMessage({
            defaultMessage: "Location de vacances",
          })}`;
        } else {
          title += ` - ${intl.formatMessage(hotelsTypeMessage, {
            type: hotel.type,
          })}`;
        }
      }

      if (hotel?.address?.city) {
        title += ` ${hotel.address.city}`;
      }

      if (hotel?.address?.department) {
        title += `, ${hotel.address?.department?.name}`;
      }

      return title;
    }

    return hotel?.hlo ?? defaultTitle;
  }, [
    hotel?.address?.city,
    hotel?.address?.department,
    hotel?.hlo,
    hotel?.title,
    hotel?.type,
    intl,
    translate,
  ]);

  const description = useMemo(() => {
    if (!hotel?.address?.city || hotel.address.department) {
      return undefined;
    }
    return [hotel.address.city, hotel.address.department].join(", ");
  }, [hotel?.address?.city, hotel?.address?.department]);

  const roomPicture = hotel
    ? getPictureForPosition({ hotel, position: 1 })
    : undefined;

  const imageUrl = roomPicture
    ? generatePampaUri({
        image: roomPicture,
        mode: "crop",
        format: "default",
        width: 1200,
      })
    : undefined;

  const structuredData = useMemo(
    () => ({
      // ---
      "@context": "https://schema.org",
      "@type": "Hotel",
      name: title,
      address: {
        "@type": "PostalAddress",
        contactType: "Customer service",
        streetAddress: hotel?.address?.address_1,
        addressLocality: hotel?.address?.city,
        addressRegion: hotel?.address?.department?.name,
        postalCode: hotel?.address?.zipcode,
        addressCountry: "FR",
      },
      // telephone: "+33 3 29 00 00 00",
      url: generateFrontPath({
        id: "@front.hotels.view",
        parameters: {
          hotelSlug: getHotelSlug({
            hotel,
            locale,
            defaultLocale,
          }),
        },
      }),
      ...(hotel?.review_infos !== undefined &&
        hotel?.review_infos?.count > 0 &&
        hotel?.review_infos?.average !== null &&
        hotel?.review_infos?.average > 0 && {
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: hotel?.review_infos?.average,
            bestRating: 10,
            worstRating: 1,
            reviewCount: hotel?.review_infos?.count,
          },
        }),
      // priceRange: "€€€",
      amenityFeature: hotel?.top_amenities
        ?.filter((amenity) => amenity.name !== null)
        .map((amenity) => ({
          "@type": "LocationFeatureSpecification",
          name: translate(amenity.name),
          value: "true",
        })),
      checkinTime: hotel?.booking_configuration?.checkin_min_time,
      checkoutTime: hotel?.booking_configuration?.checkout_max_time,
      photo: {
        "@type": "ImageObject",
        contentUrl: imageUrl,
      },
      petsAllowed: hotel?.pets?.allow_pets,
      numberOfRooms: hotel?.count_rooms,
    }),
    [locale, defaultLocale, hotel, imageUrl, title, translate],
  );

  const status =
    serverStatus ??
    getStatus({
      error: swrResponseHotel.error,
    });

  return (
    <PageErrorHandlerPublic status={status}>
      <Seo
        title={title}
        imageUrl={imageUrl}
        ogType="product"
        description={description}
        structuredData={structuredData}
        links={
          hotel
            ? (locale) =>
                generateFrontPath({
                  id: "@front.hotels.view",
                  parameters: {
                    locale,
                    hotelSlug: getHotelSlug({
                      hotel,
                      locale,
                      defaultLocale,
                    }),
                  },
                })
            : undefined
        }
      />

      <HotelView swrResponseHotel={swrResponseHotel} />
    </PageErrorHandlerPublic>
  );
}

export default Page;

// #region getInitialProps
/**
 * @typedef {import("../../types/Page").PageInitialProps & Props} InitialProps
 */
/**
 * @param {import("../../types/AppContext").PageContext} context
 * @returns {Promise<InitialProps>}
 */
Page.getInitialProps = async function (context) {
  /** @type {InitialProps} */
  const values = {
    layout: "public",
  };

  if (typeof window === "undefined") {
    const hotelSlug = getString(context.query.hotelSlug);
    const hotelId = getIdFromSlug(hotelSlug);

    if (hotelId !== undefined) {
      let hotelError = undefined;

      values.hotelResponse = await serverApiFetcher(
        getUrlHotel({ hotelId }),
        undefined,
        {
          origin: frontBaseUri.toString(),
          cookies: context.cookies,
        },
      ).catch(
        (
          /** @type {import("@raiden/library-ui/types/Api/ApiError").ApiError} */ error,
        ) => {
          hotelError = error;
          return undefined;
        },
      );

      const serverStatus = getStatus({
        error: hotelError,
      });

      values.serverStatus = serverStatus;
    } else {
      values.serverStatus = 404;
    }
  }

  return values;
};
