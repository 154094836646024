import { IoCheckmarkCircleOutline, IoWarningOutline } from "react-icons/io5";
import { defineMessage } from "react-intl";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_RESTORE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "./policies";

export const ROOM_RANKINGS_SORT_VALUE_CREATED_ASC = "created_asc";
export const ROOM_RANKINGS_SORT_VALUE_CREATED_DESC = "created_desc";
export const ROOM_RANKINGS_SORT_VALUE_EXPIRATION_ASC = "expiration_asc";
export const ROOM_RANKINGS_SORT_VALUE_EXPIRATION_DESC = "expiration_desc";

export const roomRankingsSortMessage = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} expiration_asc {Date d'expiration croissante} expiration_desc {Date d'expiration décroissante} other {{sort}}}",
});

export const ROOM_RANKINGS_SORTS = {
  [ROOM_RANKINGS_SORT_VALUE_CREATED_ASC]: {
    id: ROOM_RANKINGS_SORT_VALUE_CREATED_ASC,
  },
  [ROOM_RANKINGS_SORT_VALUE_CREATED_DESC]: {
    id: ROOM_RANKINGS_SORT_VALUE_CREATED_DESC,
  },
  [ROOM_RANKINGS_SORT_VALUE_EXPIRATION_ASC]: {
    id: ROOM_RANKINGS_SORT_VALUE_EXPIRATION_ASC,
  },
  [ROOM_RANKINGS_SORT_VALUE_EXPIRATION_DESC]: {
    id: ROOM_RANKINGS_SORT_VALUE_EXPIRATION_DESC,
  },
};

export const ROOM_RANKINGS_SORT_LIST = Object.values(ROOM_RANKINGS_SORTS);

export const ROOMS_PREFECTURE_RANKING_VALUE_FIVE_STARS = 5;
export const ROOMS_PREFECTURE_RANKING_VALUE_FOUR_STARS = 4;
export const ROOMS_PREFECTURE_RANKING_VALUE_THREE_STARS = 3;
export const ROOMS_PREFECTURE_RANKING_VALUE_TWO_STARS = 2;
export const ROOMS_PREFECTURE_RANKING_VALUE_ONE_STAR = 1;

export const ROOMS_PREFECTURE_RANKING_VALUES = {
  [ROOMS_PREFECTURE_RANKING_VALUE_FIVE_STARS]: {
    id: ROOMS_PREFECTURE_RANKING_VALUE_FIVE_STARS,
    colorChart: "#2F855A",
  },
  [ROOMS_PREFECTURE_RANKING_VALUE_FOUR_STARS]: {
    id: ROOMS_PREFECTURE_RANKING_VALUE_FOUR_STARS,
    colorChart: "#664BBA",
  },
  [ROOMS_PREFECTURE_RANKING_VALUE_THREE_STARS]: {
    id: ROOMS_PREFECTURE_RANKING_VALUE_THREE_STARS,
    colorChart: "#C05621",
  },
  [ROOMS_PREFECTURE_RANKING_VALUE_TWO_STARS]: {
    id: ROOMS_PREFECTURE_RANKING_VALUE_TWO_STARS,
    colorChart: "#2C7A7B",
  },
  [ROOMS_PREFECTURE_RANKING_VALUE_ONE_STAR]: {
    id: ROOMS_PREFECTURE_RANKING_VALUE_ONE_STAR,
    colorChart: "#AB3F7E",
  },
};

export const ROOMS_PREFECTURE_RANKING_VALUE_LIST = Object.values(
  ROOMS_PREFECTURE_RANKING_VALUES,
);

export const ROOM_RANKING_STATE_VALUE_ENABLED = "enabled";
export const ROOM_RANKING_STATE_VALUE_EXPIRED = "expired";

export const ROOM_RANKING_STATES = {
  [ROOM_RANKING_STATE_VALUE_ENABLED]: {
    id: ROOM_RANKING_STATE_VALUE_ENABLED,
    colors: ["green.600", "green.300"],
    color: "green",
    icon: IoCheckmarkCircleOutline,
  },
  [ROOM_RANKING_STATE_VALUE_EXPIRED]: {
    id: ROOM_RANKING_STATE_VALUE_EXPIRED,
    colors: ["red.600", "red.300"],
    color: "red",
    icon: IoWarningOutline,
  },
};

export const ROOM_RANKING_STATE_LIST = Object.values(ROOM_RANKING_STATES);

export const roomRankingStateMessage = defineMessage({
  defaultMessage: `{state, select,
    enabled {Valide}
    expired {Expiré}
    other {{state}}
  }`,
});

export const ROOM_RANKING_TYPE_VALUE_STAR = "star";
export const ROOM_RANKING_TYPE_VALUE_LABEL = "label";

export const ROOM_RANKING_TYPES = {
  [ROOM_RANKING_TYPE_VALUE_STAR]: {
    id: ROOM_RANKING_TYPE_VALUE_STAR,
  },
  [ROOM_RANKING_TYPE_VALUE_LABEL]: {
    id: ROOM_RANKING_TYPE_VALUE_LABEL,
  },
};

export const ROOM_RANKING_TYPE_LIST = Object.values(ROOM_RANKING_TYPES);

export const roomRankingTypeMessage = defineMessage({
  defaultMessage:
    "{type, select, star {classement} label {labelisation} other {classement {type}}}",
});

export const roomRankingTypeWithPronounsMessage = defineMessage({
  defaultMessage:
    "{type, select, star {le classement} label {la labelisation} other {classement {type}}}",
});

export const ROOM_RANKING_POLICIES_ACTIONS = {
  restore: {
    policies: [POLICIES_POLICY_VALUE_RESTORE],
  },
  delete: {
    somePolicies: [
      POLICIES_POLICY_VALUE_SOFT_DELETE,
      POLICIES_POLICY_VALUE_DELETE,
    ],
  },
};
