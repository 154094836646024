export const HOTEL_METRICS_EVENT_TYPE_VALUE_VIEW = "view";

export const HOTEL_METRICS_EVENT_TYPE_VALUE_CLICK_PHONE = "click_phone";

export const HOTEL_METRICS_EVENT_TYPE_VALUE_CLICK_WEBSITE = "click_website";

export const HOTEL_METRICS_EVENT_TYPE_VALUE_SHARE = "share";

export const HOTEL_METRICS_EVENT_TYPES = {
  [HOTEL_METRICS_EVENT_TYPE_VALUE_VIEW]: {
    id: HOTEL_METRICS_EVENT_TYPE_VALUE_VIEW,
  },
  [HOTEL_METRICS_EVENT_TYPE_VALUE_CLICK_PHONE]: {
    id: HOTEL_METRICS_EVENT_TYPE_VALUE_CLICK_PHONE,
  },
  [HOTEL_METRICS_EVENT_TYPE_VALUE_CLICK_WEBSITE]: {
    id: HOTEL_METRICS_EVENT_TYPE_VALUE_CLICK_WEBSITE,
  },
  [HOTEL_METRICS_EVENT_TYPE_VALUE_SHARE]: {
    id: HOTEL_METRICS_EVENT_TYPE_VALUE_SHARE,
  },
};

export const HOTEL_METRICS_EVENT_TYPES_LIST = Object.values(
  HOTEL_METRICS_EVENT_TYPES,
);
