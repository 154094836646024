import { RGrid } from "@raiden/library-ui/components/RGrid";
import { memo } from "react";
import { useIntl } from "react-intl";
import { HotelViewInfoItem } from "./InfoItem";

export const HotelViewRoomInfo = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Room").Room} room
   * @property {boolean} withWCs
   * @property {boolean} withBabyFriendly
   */
  /**
   * @param {Props} props
   */
  function HotelViewRoomInfo({ room, withWCs, withBabyFriendly }) {
    const intl = useIntl();

    if (!room.composition_infos) {
      return null;
    }

    return (
      <RGrid minCellWidth="100px">
        <HotelViewInfoItem
          icon="ms_people"
          labelVariant="text-sm"
          label={intl.formatMessage(
            {
              defaultMessage:
                "{count, plural, =0 {# personne} =1 {# personne} other {# personnes}}",
            },
            {
              count: room.legal_capacity ?? room.capacity,
            },
          )}
        />

        {(room.composition_infos.count_beds ?? 0) > 0 && (
          <HotelViewInfoItem
            icon="ms_bed"
            labelVariant="text-sm"
            label={intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, =0 {# lit} =1 {# lit} other {# lits}}",
              },
              {
                count: room.composition_infos.count_beds,
              },
            )}
          />
        )}

        {(room.composition_infos.count_bathrooms ?? 0) > 0 && (
          <HotelViewInfoItem
            icon="ms_bed"
            labelVariant="text-sm"
            label={intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, =0 {# salle d'eau} =1 {# salle d'eau} other {# salles d'eau}}",
              },
              {
                count: room.composition_infos.count_bathrooms,
              },
            )}
          />
        )}

        {withWCs && (room.composition_infos.count_wc ?? 0) > 0 && (
          <HotelViewInfoItem
            icon="ms_wc"
            labelVariant="text-sm"
            label={intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, =0 {# WC} =1 {# WC} other {# WCs}}",
              },
              {
                count: room.composition_infos.count_wc,
              },
            )}
          />
        )}

        {withBabyFriendly && room.baby_reception && (
          <HotelViewInfoItem
            icon="sf_baby"
            labelVariant="text-sm"
            label={intl.formatMessage({ defaultMessage: "Équipé pour bébés" })}
          />
        )}
      </RGrid>
    );
  },
);
