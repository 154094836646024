import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Stack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import ApiErrorMessage from "@raiden/library-ui/components/ApiErrorMessage";
import { Icon } from "@raiden/library-ui/components/Icon";
import { memo, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useBottomNavBar from "../../../hooks/useNavBar";
import { BookingsCreateEstimation } from "../../CartBookings/Create/Estimation";
import { CartBookingsCreateForm } from "../../CartBookings/Create/Form";
import { ContactCustomer } from "./ContactCustomer";
import { WidgetITEA } from "./WidgetITEA";
import { Sncf } from "./Sncf";
import { Insurance } from "./Insurance";
import FormObserver from "@raiden/library-ui/components/ReactHookForm/FormObserver";

export const CartBookingBottomBar = memo(
  /**
   * @typedef {object} Props
   * @property {import("react-hook-form").UseFormReturn<import("../../CartBookings/Create/Handler").CartBookingsCreateFormValues>} form
   * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
   * @property {() => void} submit
   * @property {boolean} isBookableOnline
   * @property {boolean} isPlanExpert
   * @property {boolean} isLoading
   * @property {boolean} isOpen
   * @property {() => void} onOpen
   * @property {() => void} onClose
   * @property {string} [initialCheckin]
   * @property {string} [initialCheckout]
   */
  /**
   * @param {Props} props
   */
  function CartBookingBottomBar({
    form,
    hotel,
    submit,
    isBookableOnline,
    isPlanExpert,
    isLoading,
    isOpen,
    onOpen,
    onClose,
    initialCheckin,
    initialCheckout,
  }) {
    const disclosureModalContactCustomer = useDisclosure();

    const { navBarHeight } = useBottomNavBar();

    const roomId = form.getValues("room_id");

    const intl = useIntl();

    const isMobile = useBreakpointValue({ base: true, lg: false });

    useEffect(() => {
      if (!isMobile) {
        onClose();
      }
    }, [isMobile, onClose]);

    return (
      <>
        <Flex
          display={{ base: "flex", lg: "none" }}
          direction="column"
          position="fixed"
          left="0"
          right="0"
          bottom={`${navBarHeight}px`}
          height={isOpen ? `calc(100dvh - ${navBarHeight}px)` : "57px"}
          overflow="hidden"
          transition="all .25s ease"
          backgroundColor="#fff"
          borderTopRadius={isOpen ? "0" : "20px"}
          boxShadow="0 -1px 0 0 rgb(219, 219, 219)"
          overflowY="auto"
          zIndex={1}>
          <Stack flexGrow={1} spacing="12px">
            <Box
              position="sticky"
              top="0"
              zIndex={1}
              backgroundColor="#fff"
              px="16px"
              py="8px">
              <Box flexGrow={1} display={isOpen ? "block" : "none"}>
                <IconButton
                  onClick={onClose}
                  icon={<Icon icon="ms_close" size="24px" />}
                  variant="ghost"
                  aria-label={intl.formatMessage({ defaultMessage: "Fermer" })}>
                  x
                </IconButton>
              </Box>

              <Button
                onClick={onOpen}
                colorScheme="brandSecondary"
                display={isOpen ? "none" : "inline-block"}
                w="full">
                <FormattedMessage defaultMessage="Réserver" />
              </Button>
            </Box>

            {isOpen && (
              <Flex px="16px" pb="12px" flexGrow={1}>
                {hotel.itea_api_key !== null ? (
                  <Stack spacing="12px" w="full">
                    <WidgetITEA
                      hotel={hotel}
                      iteaApiKey={hotel.itea_api_key}
                      checkin={initialCheckin}
                      checkout={initialCheckout}
                    />

                    <FormObserver control={form.control} name="checkin">
                      {(checkin) => (
                        <FormObserver control={form.control} name="checkout">
                          {(checkout) => (
                            <ContactCustomer
                              hotel={hotel}
                              roomId={roomId}
                              checkin={checkin}
                              checkout={checkout}
                              disclosure={disclosureModalContactCustomer}
                              withContactLink={
                                isBookableOnline && !isPlanExpert
                              }
                              withWebsite={!isPlanExpert}
                              withWebsiteInDescription={true}
                            />
                          )}
                        </FormObserver>
                      )}
                    </FormObserver>

                    <Sncf form={form} hotel={hotel} />

                    <Insurance />
                  </Stack>
                ) : (
                  <Stack
                    as="form"
                    id="cart-form"
                    onSubmit={submit}
                    w="full"
                    spacing="12px">
                    <CartBookingsCreateForm form={form} hotel={hotel} />

                    <Divider />

                    <BookingsCreateEstimation
                      form={form}
                      shouldRenderMarkupAmount={isPlanExpert}
                      shouldRenderTouristTaxAmount={isPlanExpert}
                      isBookableOnline={isBookableOnline}
                    />

                    {isBookableOnline && (
                      <Stack flexGrow={1} justify="flex-end">
                        <ApiErrorMessage withCaughtErrors={true} />

                        <Button
                          type="submit"
                          isLoading={isLoading}
                          colorScheme="brandSecondary"
                          minH="47px"
                          w="full">
                          <FormattedMessage defaultMessage="Réserver" />
                        </Button>
                      </Stack>
                    )}

                    {!isBookableOnline && !isPlanExpert && (
                      <Stack flexGrow={1} justify="flex-end">
                        <Button
                          onClick={disclosureModalContactCustomer.onOpen}
                          colorScheme="brandSecondary"
                          minH="47px"
                          w="full">
                          <FormattedMessage defaultMessage="Contacter le propriétaire" />
                        </Button>
                      </Stack>
                    )}

                    <Divider />

                    <FormObserver control={form.control} name="checkin">
                      {(checkin) => (
                        <FormObserver control={form.control} name="checkout">
                          {(checkout) => (
                            <ContactCustomer
                              hotel={hotel}
                              roomId={roomId}
                              checkin={checkin}
                              checkout={checkout}
                              disclosure={disclosureModalContactCustomer}
                              withContactLink={
                                isBookableOnline && !isPlanExpert
                              }
                              withWebsite={!isPlanExpert}
                              withWebsiteInDescription={true}
                            />
                          )}
                        </FormObserver>
                      )}
                    </FormObserver>

                    <Divider />

                    <Sncf form={form} hotel={hotel} />

                    <Divider />

                    <Insurance />
                  </Stack>
                )}
              </Flex>
            )}
          </Stack>
        </Flex>
      </>
    );
  },
);
