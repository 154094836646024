import {
  ROOM_RANKING_STATE_VALUE_ENABLED,
  ROOM_RANKING_STATE_VALUE_EXPIRED,
} from "@raiden/library-ui/constants/roomRanking";
import dayjs from "dayjs";

/**
 * Returns true if the label ranking is enabled or expired for less than six months
 * @param {import("@raiden/library-ui/types/RoomRanking").RoomRanking} lastLabelRanking
 */
function isLabelRankingEnabledOrExpiredForLessThanSixMonths(lastLabelRanking) {
  const today = dayjs();

  return (
    lastLabelRanking.state === ROOM_RANKING_STATE_VALUE_ENABLED ||
    (lastLabelRanking.state === ROOM_RANKING_STATE_VALUE_EXPIRED &&
      today.diff(dayjs(lastLabelRanking.expiration_date), "month") < 6)
  );
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/RoomRanking").RoomRanking | null} [lastLabelRanking]
 */

/**
 * @param {Props} props
 */
export function shouldDisplayLabelRanking({ lastLabelRanking }) {
  if (lastLabelRanking && lastLabelRanking?.value) {
    return isLabelRankingEnabledOrExpiredForLessThanSixMonths(lastLabelRanking);
  }

  return false;
}
