import {
  IoBanOutline,
  IoCheckmarkOutline,
  IoTimeOutline,
} from "react-icons/io5";
import { defineMessage } from "react-intl";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_RESTORE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "./policies";

export const BLACKLIST_CONTACTS_SORT_VALUE_CREATED_ASC = "created_asc";
export const BLACKLIST_CONTACTS_SORT_VALUE_CREATED_DESC = "created_desc";

export const BLACKLIST_CONTACTS_SORTS = {
  [BLACKLIST_CONTACTS_SORT_VALUE_CREATED_ASC]: {
    id: BLACKLIST_CONTACTS_SORT_VALUE_CREATED_ASC,
  },
  [BLACKLIST_CONTACTS_SORT_VALUE_CREATED_DESC]: {
    id: BLACKLIST_CONTACTS_SORT_VALUE_CREATED_DESC,
  },
};

export const BLACKLIST_CONTACTS_SORT_LIST = Object.values(
  BLACKLIST_CONTACTS_SORTS,
);

export const blackListContactSortMessage = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} other {{sort}}}",
});

export const BLACKLIST_CONTACTS_AUTHOR_TYPE_VALUE_ADMIN = "admin";
export const BLACKLIST_CONTACTS_AUTHOR_TYPE_VALUE_CUSTOMER = "customer";

export const BLACKLIST_CONTACTS_AUTHOR_TYPES = {
  [BLACKLIST_CONTACTS_AUTHOR_TYPE_VALUE_ADMIN]: {
    id: BLACKLIST_CONTACTS_AUTHOR_TYPE_VALUE_ADMIN,
  },
  [BLACKLIST_CONTACTS_AUTHOR_TYPE_VALUE_CUSTOMER]: {
    id: BLACKLIST_CONTACTS_AUTHOR_TYPE_VALUE_CUSTOMER,
  },
};

export const BLACKLIST_CONTACTS_AUTHOR_TYPES_LIST = Object.values(
  BLACKLIST_CONTACTS_AUTHOR_TYPES,
);

export const blackListContactsAuthorTypeMessage = defineMessage({
  defaultMessage:
    "{authorType, select, admin {administrateur} customer {propriétaire} other {{authorType}}}",
});

export const BLACKLIST_CONTACTS_POLICIES_ACTIONS = {
  restore: {
    policies: [POLICIES_POLICY_VALUE_RESTORE],
  },
  delete: {
    somePolicies: [
      POLICIES_POLICY_VALUE_SOFT_DELETE,
      POLICIES_POLICY_VALUE_DELETE,
    ],
  },
};

export const BLACKLIST_CONTACTS_STATE_VALUE_PENDING = "pending";
export const BLACKLIST_CONTACTS_STATE_VALUE_ACCEPTED = "accepted";
export const BLACKLIST_CONTACTS_STATE_VALUE_REJECTED = "rejected";

export const BLACKLIST_CONTACTS_STATES = {
  [BLACKLIST_CONTACTS_STATE_VALUE_PENDING]: {
    id: BLACKLIST_CONTACTS_STATE_VALUE_PENDING,
    colors: ["orange.600", "orange.300"],
    icon: IoTimeOutline,
  },
  [BLACKLIST_CONTACTS_STATE_VALUE_ACCEPTED]: {
    id: BLACKLIST_CONTACTS_STATE_VALUE_ACCEPTED,
    colors: ["green.600", "green.300"],
    icon: IoCheckmarkOutline,
  },
  [BLACKLIST_CONTACTS_STATE_VALUE_REJECTED]: {
    id: BLACKLIST_CONTACTS_STATE_VALUE_REJECTED,
    colors: ["red.600", "red.300"],
    icon: IoBanOutline,
  },
};

export const BLACKLIST_CONTACTS_STATES_LIST = Object.values(
  BLACKLIST_CONTACTS_STATES,
);

export const blacklistContactStateMessage = defineMessage({
  defaultMessage:
    "{state, select, pending {en attente} accepted {accepté} rejected {refusé} other {{state}}}",
});

export const BLACKLIST_CONTACTS_TYPE_VALUE_EMAIL = "email";
export const BLACKLIST_CONTACTS_TYPE_VALUE_PHONE = "phone";

export const BLACKLIST_CONTACTS_TYPES = {
  [BLACKLIST_CONTACTS_TYPE_VALUE_EMAIL]: {
    id: BLACKLIST_CONTACTS_TYPE_VALUE_EMAIL,
  },
  [BLACKLIST_CONTACTS_TYPE_VALUE_PHONE]: {
    id: BLACKLIST_CONTACTS_TYPE_VALUE_PHONE,
  },
};

export const BLACKLIST_CONTACTS_TYPES_LIST = Object.values(
  BLACKLIST_CONTACTS_TYPES,
);

export const blacklistContactTypeMessage = defineMessage({
  defaultMessage:
    "{type, select, email {email} phone {téléphone} other {{type}}}",
});
