import useRequest from "@raiden/library-ui/hooks/useRequest";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";

/**
 * Handles the api call to create a hotel metrics event.
 * Used to track user interactions (click on phone, click on customer website) on the hotel page.
 * @returns {{ createHotelMetrics: (param0: { hotelId: number, eventType: keyof typeof import("@raiden/library-ui/constants/hotelMetrics").HOTEL_METRICS_EVENT_TYPES }) => void }}
 */
export function useCreateHotelMetricsEvent() {
  const { request } = useRequest();

  /**
   * @param {object} param0
   * @param {number} param0.hotelId
   * @param {keyof typeof import("@raiden/library-ui/constants/hotelMetrics").HOTEL_METRICS_EVENT_TYPES} param0.eventType
   */

  function createHotelMetrics({ hotelId, eventType }) {
    request(
      generateApiUrl({
        id: "@hotelsMetrics.create",
        parameters: { hotelId },
      }),
      {
        method: "POST",
        body: {
          data: {
            event_type: eventType,
          },
        },
      },
    ).catch((error) => {
      console.error(
        "An error occurred while creating a metric event : ",
        error,
      );
    });
  }

  return { createHotelMetrics };
}
