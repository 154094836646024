import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { getFloorMessage } from "@raiden/library-ui/helpers/rooms/getFloorMessage/getFloorMessage";
import {
  amenitiesCategoryMessage,
  AMENITIES_CATEGORY_VALUE_ACCESSIBILITY,
  AMENITIES_CATEGORY_VALUE_BED,
  AMENITIES_CATEGORY_VALUE_CHARGING_STATION,
  AMENITIES_CATEGORY_VALUE_INSIDE,
  AMENITIES_CATEGORY_VALUE_SANITARY,
} from "@raiden/library-ui/constants/amenities";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { HotelViewSection } from "./Section";
import { RGrid } from "@raiden/library-ui/components/RGrid";
import { Icon } from "@raiden/library-ui/components/Icon";
import { HotelViewPictures } from "./Pictures";
import { HotelsAmenityAboutModal } from "../../../components/Hotels/AmenityAboutModal";

export const HotelViewRoomCompositionModal = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Room").RoomComposition} roomComposition
   * @property {boolean} isOpen
   * @property {() => void} onClose
   */
  /**
   * @param {Props} props
   */
  function HotelViewRoomCompositionModal({ roomComposition, isOpen, onClose }) {
    const intl = useIntl();

    const translate = useTranslate();

    const groupedAmenities = useMemo(() => {
      /** @type {import("@raiden/library-ui/types/Amenity").AmenityCategory[]} */
      const allowedCategories = [
        AMENITIES_CATEGORY_VALUE_INSIDE,
        AMENITIES_CATEGORY_VALUE_ACCESSIBILITY,
      ];

      if (roomComposition.allow_beds) {
        allowedCategories.push(AMENITIES_CATEGORY_VALUE_BED);
      }

      if (roomComposition.allow_sanitaries) {
        allowedCategories.push(AMENITIES_CATEGORY_VALUE_SANITARY);
      }

      /** @type {Record<string, import("@raiden/library-ui/types/Room").RoomAmenity[]>} */
      const groupedAmenities =
        roomComposition.amenities
          ?.filter((amenity) => allowedCategories.includes(amenity?.category))
          .reduce((acc, amenity) => {
            if (!acc[amenity.category]) {
              acc[amenity.category] = [];
            }
            acc[amenity.category].push(amenity);
            return acc;
          }, {}) ?? {};
      return groupedAmenities;
    }, [
      roomComposition.allow_beds,
      roomComposition.allow_sanitaries,
      roomComposition.amenities,
    ]);

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        scrollBehavior="inside"
        size="2xl">
        <ModalOverlay />

        <ModalContent mx="1rem">
          <ModalHeader borderBottomWidth="1px">
            <HStack justify="center" spacing="16px">
              <Flex justify="center" ml="48px" flexGrow={1}>
                {roomComposition.name ? (
                  <Wrap spacing="12px">
                    <Text variant="h4" textAlign="center">
                      {translate(roomComposition.name)}
                    </Text>
                  </Wrap>
                ) : (
                  <FormattedMessage defaultMessage="Pièce" />
                )}
              </Flex>

              <IconButton
                onClick={onClose}
                icon={<Icon icon="ms_close" />}
                aria-label={intl.formatMessage({ defaultMessage: "Fermer" })}
                variant="outline"
                size="sm"
                borderRadius="full"
              />
            </HStack>
          </ModalHeader>

          <ModalBody px="0" py="16px">
            <Stack spacing="16px">
              {roomComposition.pictures && roomComposition.pictures.length > 0 && (
                <Box px="16px">
                  <HotelViewPictures roomPictures={roomComposition.pictures} />
                </Box>
              )}

              {(roomComposition.floor !== null ||
                roomComposition.surface !== null) && (
                <Wrap spacing="16px" px="16px">
                  {roomComposition.floor !== null && (
                    <Text variant="text-md" color="gray.500">
                      {intl.formatMessage(
                        getFloorMessage({ floor: roomComposition.floor }),
                        { floor: roomComposition.floor },
                      )}
                    </Text>
                  )}

                  {roomComposition.surface !== null && (
                    <Text variant="text-md" color="gray.500">
                      <FormattedMessage
                        defaultMessage="{surface} m²"
                        values={{
                          surface: roomComposition.surface,
                        }}
                      />
                    </Text>
                  )}
                </Wrap>
              )}

              {roomComposition.about && (
                <Box px="16px">
                  <HotelViewSection
                    title={intl.formatMessage({
                      defaultMessage: "Description",
                    })}>
                    <Text variant="text-sm">
                      {translate(roomComposition.about)}
                    </Text>
                  </HotelViewSection>
                </Box>
              )}

              {groupedAmenities &&
                Object.entries(groupedAmenities).map(
                  ([category, roomAmenities]) => (
                    <Box px="16px" key={category}>
                      <HotelViewSection
                        key={category}
                        // Trick to change the category value charging-station to charging_station so that intl works (for some reason, backend uses kebab case for charging station)
                        title={intl.formatMessage(amenitiesCategoryMessage, {
                          category:
                            category ===
                            AMENITIES_CATEGORY_VALUE_CHARGING_STATION
                              ? "charging_station"
                              : category,
                        })}>
                        <RGrid gap="16px" minCellWidth="150px">
                          {roomAmenities.map((roomAmenity) => (
                            <HStack spacing="12px" key={roomAmenity.id}>
                              <Icon
                                icon={roomAmenity.icon ?? "ms_star"}
                                size="16px"
                              />

                              <Text variant="text-sm">
                                {roomAmenity.quantity !== null &&
                                  roomAmenity.quantity > 0 && (
                                    <span>{roomAmenity.quantity} x </span>
                                  )}

                                {translate(roomAmenity.name)}
                              </Text>

                              <HotelsAmenityAboutModal
                                roomAmenity={roomAmenity}
                              />
                            </HStack>
                          ))}
                        </RGrid>
                      </HotelViewSection>
                    </Box>
                  ),
                )}
            </Stack>
          </ModalBody>

          <ModalFooter borderTopWidth="1px">
            <Wrap justify="flex-end">
              <Button onClick={onClose} variant="outline">
                <Text variant="button-md">
                  <FormattedMessage defaultMessage="Retour" />
                </Text>
              </Button>
            </Wrap>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
);
