import {
  Box,
  FormHelperText,
  InputRightAddon,
  Spinner,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { BLACKLIST_CONTACTS_TYPE_VALUE_PHONE } from "../../../constants/blacklistContact";
import { useApi } from "../../../hooks/useApi";
import useDebounce from "../../../hooks/useDebounce";
import useRequest from "../../../hooks/useRequest";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import { Icon } from "../../Icon";
import InputPhone from "../InputPhone";

/**
 * Component built on top of InputPhone that verifies if the phone number is blacklisted
 * @param {{
 * nameCountry: string,
 * nameNumber: string,
 * countries: import("../../../types/Country").Country[],
 * inputProps?: any,
 * placeholder?: string,
 * isRequired?: boolean,
 * isDisabled?: boolean,
 * shouldDisplayFlag?: boolean,
 * label?: string,
 * helperText?: string,
 * advisedText?: any,
 * rightElement?: import("react").ReactNode,
 * value: string,
 * valuePhoneCountry: string,
 * } & import("@chakra-ui/react").FormControlProps} props
 */
export default function InputPhoneWithBlacklistVerify(props) {
  const {
    nameNumber,
    nameCountry,
    countries,
    isRequired,
    inputProps,
    placeholder,
    isDisabled,
    shouldDisplayFlag = false,
    label = undefined,
    helperText,
    advisedText,
    rightElement,
    value,
    valuePhoneCountry,
    ...otherProps
  } = props;

  const intl = useIntl();

  const [isPhoneBlacklisted, setIsPhoneBlacklisted] = useState(
    /** @type {boolean | null} */ (null),
  );

  const { toastError } = useRequest();

  const [phone, setPhone] = useState(value);

  useEffect(() => {
    // Reset the state when the value changes and update the phone number (in case of a controlled component like in booking form)
    setIsPhoneBlacklisted(null);

    if (value !== phone) {
      setPhone(value);
    }
  }, [phone, value]);

  const debouncedPhone = useDebounce(phone, { delay: 1000 });

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("../../../types/BlacklistContact").BlacklistContactVerifyData>} */
  const { swrResponse } = useApi(
    debouncedPhone?.length >= 5
      ? generateApiUrl({
          id: "@blacklistContacts.verify",
          query: {
            phone_number: debouncedPhone,
            phone_country: valuePhoneCountry,
            type: BLACKLIST_CONTACTS_TYPE_VALUE_PHONE,
          },
        })
      : null,
    {
      swrConfig: {
        keepPreviousData: true,
        onSuccess: (data) => {
          setIsPhoneBlacklisted(data.data?.blacklist);
        },
        onError: () => {
          setIsPhoneBlacklisted(null);
          toastError({
            title: intl.formatMessage({
              defaultMessage:
                "Une erreur est survenue lors de la vérification du téléphone",
            }),
          });
        },
      },
    },
  );

  const handleChange = useCallback((e) => {
    setPhone(e.target.value);
  }, []);

  const inputColor = useMemo(() => {
    if (isPhoneBlacklisted === null) {
      return "initial";
    }

    return isPhoneBlacklisted ? "red.500" : undefined;
  }, [isPhoneBlacklisted]);

  return (
    <InputPhone
      nameCountry={nameCountry}
      nameNumber={nameNumber}
      isRequired={isRequired}
      inputProps={{ ...inputProps, color: inputColor }}
      placeholder={placeholder}
      isDisabled={isDisabled}
      shouldDisplayFlag={shouldDisplayFlag}
      label={label}
      helperText={
        isPhoneBlacklisted ? (
          <FormHelperText color="red.500" mt=".25rem" pl="1rem">
            {intl.formatMessage({
              defaultMessage:
                "Ce numéro de téléphone a été signalé comme frauduleux",
            })}
          </FormHelperText>
        ) : (
          helperText
        )
      }
      advisedText={advisedText}
      rightElement={
        swrResponse.isValidating ? (
          <InputRightAddon>
            <Box>
              <Spinner size="sm" />
            </Box>
          </InputRightAddon>
        ) : isPhoneBlacklisted ? (
          <InputRightAddon>
            <Icon icon="ms_report" color="red.500" />
          </InputRightAddon>
        ) : (
          rightElement
        )
      }
      countries={countries ?? []}
      onChange={handleChange}
      {...otherProps}
    />
  );
}
