import { HStack, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { Icon } from "@raiden/library-ui/components/Icon";
import { HotelsAmenityAboutModal } from "../../../components/Hotels/AmenityAboutModal";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Room").RoomAmenity} roomAmenity
 */
/**
 * @param {Props} props
 */
export function HotelViewTopAmenity({ roomAmenity }) {
  const translate = useTranslate();

  return (
    <HStack
      spacing="12px"
      backgroundColor="gray.100"
      borderRadius="10px"
      py="8px"
      px="12px">
      <Icon
        icon={roomAmenity.icon ?? "ms_star"}
        size="24px"
        color="brandPrimary.300"
      />

      <Text variant="text-label">{translate(roomAmenity.name)}</Text>

      <HotelsAmenityAboutModal roomAmenity={roomAmenity} />
    </HStack>
  );
}
