import {
  Box,
  Button,
  DarkMode,
  Divider,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import ApiErrorMessage from "@raiden/library-ui/components/ApiErrorMessage";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "../../../components/Card/Card";
import { BookingsCreateEstimation } from "../../CartBookings/Create/Estimation";
import { CartBookingsCreateForm } from "../../CartBookings/Create/Form";
import { ContactCustomer } from "./ContactCustomer";
import { WidgetITEA } from "./WidgetITEA";
import { WebHotelContactCreateModal } from "../../WebHotelContact/Create/Modal";

import { Sncf } from "./Sncf";
import { Insurance } from "./Insurance";
import FormObserver from "@raiden/library-ui/components/ReactHookForm/FormObserver";

export const CartBookingSidebar = memo(
  /**
   * @typedef {object} Props
   * @property {import("react-hook-form").UseFormReturn<import("../../CartBookings/Create/Handler").CartBookingsCreateFormValues>} form
   * @property {() => void} submit
   * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
   * @property {boolean} isBookableOnline
   * @property {boolean} isPlanExpert
   * @property {boolean} isLoading
   * @property {string} [initialCheckin]
   * @property {string} [initialCheckout]
   * @property {boolean} isOpenBottomBar
   */
  /**
   * @param {Props} props
   */
  function CartBookingSidebar({
    form,
    submit,
    hotel,
    isBookableOnline,
    isLoading,
    isPlanExpert,
    isOpenBottomBar,
    initialCheckin,
    initialCheckout,
  }) {
    const disclosureModalContactCustomer = useDisclosure();

    return (
      <>
        <FormObserver control={form.control} name="checkin">
          {(checkin) => (
            <FormObserver control={form.control} name="checkout">
              {(checkout) => (
                <FormObserver control={form.control} name="room_id">
                  {(roomId) => (
                    <WebHotelContactCreateModal
                      hotelId={hotel.id}
                      isOpen={disclosureModalContactCustomer.isOpen}
                      onClose={disclosureModalContactCustomer.onClose}
                      customer={hotel.customer ?? null}
                      roomId={roomId}
                      checkin={checkin}
                      checkout={checkout}
                      withWebsite={!isPlanExpert}
                      roomList={hotel.rooms ?? []}
                    />
                  )}
                </FormObserver>
              )}
            </FormObserver>
          )}
        </FormObserver>

        <Card>
          <DarkMode>
            <Box
              borderTopRadius="20px"
              overflow="hidden"
              backgroundColor="brandPrimary.700"
              p="16px">
              <Text variant="h5" textAlign="center">
                <FormattedMessage defaultMessage="Je réserve" />
              </Text>
            </Box>
          </DarkMode>

          <Stack
            spacing="12px"
            divider={<StackDivider />}
            maxW="360px"
            p="16px">
            {hotel.itea_api_key !== null ? (
              <>
                {!isOpenBottomBar && ( // makes sure we unload the widget when the bottom bar is opened
                  <WidgetITEA
                    hotel={hotel}
                    iteaApiKey={hotel.itea_api_key}
                    checkin={initialCheckin}
                    checkout={initialCheckout}
                  />
                )}
              </>
            ) : (
              <form id="cart-form" onSubmit={submit}>
                <Stack spacing="12px">
                  {hotel.rooms && hotel.rooms.length > 0 && (
                    <CartBookingsCreateForm form={form} hotel={hotel} />
                  )}

                  <Divider />

                  <BookingsCreateEstimation
                    form={form}
                    shouldRenderMarkupAmount={isPlanExpert}
                    shouldRenderTouristTaxAmount={isPlanExpert}
                    isBookableOnline={isBookableOnline}
                  />

                  {isBookableOnline && (
                    <Box>
                      <ApiErrorMessage withCaughtErrors={true} />

                      <Button
                        type="submit"
                        isLoading={isLoading}
                        colorScheme="brandSecondary"
                        w="full">
                        <FormattedMessage defaultMessage="Réserver" />
                      </Button>
                    </Box>
                  )}

                  {!isBookableOnline && !isPlanExpert && (
                    <Button
                      onClick={disclosureModalContactCustomer.onOpen}
                      colorScheme="brandSecondary"
                      w="full">
                      <FormattedMessage defaultMessage="Contacter le propriétaire" />
                    </Button>
                  )}
                </Stack>
              </form>
            )}

            <FormObserver control={form.control} name="checkin">
              {(checkin) => (
                <FormObserver control={form.control} name="checkout">
                  {(checkout) => (
                    <FormObserver control={form.control} name="room_id">
                      {(roomId) => (
                        <ContactCustomer
                          hotel={hotel}
                          roomId={roomId}
                          checkin={checkin}
                          checkout={checkout}
                          withContactLink={isBookableOnline && !isPlanExpert}
                          withWebsite={!isPlanExpert}
                          withWebsiteInDescription={false}
                        />
                      )}
                    </FormObserver>
                  )}
                </FormObserver>
              )}
            </FormObserver>

            <Sncf form={form} hotel={hotel} />

            <Insurance />
          </Stack>
        </Card>
      </>
    );
  },
);
