import { Box, Collapse, Stack } from "@chakra-ui/react";
import ApiErrorMessage from "@raiden/library-ui/components/ApiErrorMessage";
import InputPhone from "@raiden/library-ui/components/Form/InputPhone";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { RGrid } from "@raiden/library-ui/components/RGrid";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { CheckinCheckoutInput } from "@raiden/library-ui/components/Form/CheckinCheckoutInput";
import FormObserver from "@raiden/library-ui/components/ReactHookForm/FormObserver";
import FormErrorObserverRHF from "@raiden/library-ui/components/ReactHookForm/FormErrorObserverRHF";
import FormErrorMessageRHF from "@raiden/library-ui/components/ReactHookForm/FormErrorMessageRHF";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { Select } from "@raiden/library-ui/components/Form/Select/Select";

/**
 * @typedef {object} Props
 * @property {import("react-hook-form").UseFormReturn<import("./Handler").WebHotelContactCreateFormValues>} form
 * @property {number} roomId
 * @property {import("@raiden/library-ui/types/Room").Room[]} roomList
 */
/**
 * @param {Props} props
 */
export function WebHotelContactCreateForm({ form, roomId, roomList }) {
  const intl = useIntl();

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Country").Country[]>} */
  const { swrResponse: swrResponseCountries } = useApi(
    generateApiUrl({ id: "@countries.search", query: { per_page: 250 } }),
  );

  const translate = useTranslate();

  const roomsOptions = useMemo(() => {
    /** @type {import("@raiden/library-ui/components/Form/Select/Select").SelectOption<number, undefined>[]}*/
    const options =
      roomList?.map((room) => ({
        value: room.id,
        label: translate(room.name) ?? String(room.id),
        data: undefined,
      })) ?? [];
    return options;
  }, [roomList, translate]);

  const countries = useMemo(() => {
    return swrResponseCountries.data?.data ?? [];
  }, [swrResponseCountries.data?.data]);

  const handleChangeCheckinCheckout = useCallback(
    ({ checkin, checkout }) => {
      form.setValue("data.checkin", checkin, { shouldDirty: true });
      form.setValue("data.checkout", checkout, { shouldDirty: true });
    },
    [form],
  );

  return (
    <Stack spacing="1rem">
      <Box>
        <ApiErrorMessage withCaughtErrors={true} />

        {roomList.length > 1 && (
          <Box mb="1rem">
            <FormControlRHF
              control={form.control}
              name="data.room_id"
              label={intl.formatMessage({
                defaultMessage: "Choisir l'hébergement",
              })}
              renderWithFormControl={(field) => (
                <Select
                  {...field}
                  variant="outline"
                  options={roomsOptions}
                  w="full"
                />
              )}
            />
          </Box>
        )}

        <RGrid minCellWidth="300px">
          <FormControlRHF
            control={form.control}
            name="data.first_name"
            rules={{ required: true }}
            type="text"
            label={intl.formatMessage({ defaultMessage: "Prénom" })}
          />

          <FormControlRHF
            control={form.control}
            name="data.last_name"
            rules={{ required: true }}
            type="text"
            label={intl.formatMessage({ defaultMessage: "Nom" })}
          />
        </RGrid>
      </Box>

      <FormObserver control={form.control} name="data.checkin">
        {(checkin) => (
          <FormObserver control={form.control} name="data.checkout">
            {(checkout) => (
              <>
                <CheckinCheckoutInput
                  bookingId={null}
                  roomId={roomId}
                  capacity={1}
                  checkin={checkin}
                  checkout={checkout}
                  includeOnPast={false}
                  includeRestricted={false}
                  includeWithoutPrices={true}
                  onChange={handleChangeCheckinCheckout}
                />

                {["data.checkin", "data.checkout"].map((name) => (
                  <FormErrorObserverRHF
                    key={name}
                    name={name}
                    render={({ hasError, error }) => (
                      <Collapse in={hasError} unmountOnExit={true}>
                        <FormErrorMessageRHF error={error} />
                      </Collapse>
                    )}
                  />
                ))}
              </>
            )}
          </FormObserver>
        )}
      </FormObserver>

      <InputPhone
        countries={countries}
        nameCountry="data.phone_country"
        nameNumber="data.phone_number"
        isRequired={true}
      />

      <RGrid minCellWidth="300px">
        <FormControlRHF
          control={form.control}
          name="data.email"
          type="email"
          rules={{
            required: true,
            onChange: () => {
              form.clearErrors("data.email_verify");
            },
            validate: (value) =>
              value === form.getValues("data.email_verify")
                ? true
                : intl.formatMessage({
                    defaultMessage: "Les adresses email ne correspondent pas",
                  }),
          }}
          label={intl.formatMessage({ defaultMessage: "Email" })}
        />

        <FormControlRHF
          control={form.control}
          name="data.email_verify"
          type="email"
          rules={{
            required: true,
            onChange: () => {
              form.clearErrors("data.email");
            },
            validate: (value) =>
              value === form.getValues("data.email")
                ? true
                : intl.formatMessage({
                    defaultMessage: "Les adresses email ne correspondent pas",
                  }),
          }}
          label={intl.formatMessage({
            defaultMessage: "Répéter votre adresse email",
          })}
          placeholder={intl.formatMessage({
            defaultMessage: "Email",
          })}
        />
      </RGrid>

      <FormControlRHF
        control={form.control}
        name="data.content"
        type="textarea"
        rules={{ required: true, maxLength: 1000 }}
        label={intl.formatMessage({ defaultMessage: "Message" })}
        withCharactersCounter={true}
      />
    </Stack>
  );
}
