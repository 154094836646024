import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Room").RoomAmenity} roomAmenity
 */

/**
 * @param {Props} props
 */
export function HotelsAmenityAboutModal({ roomAmenity }) {
  const intl = useIntl();

  const translate = useTranslate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (roomAmenity.about === null) {
    return null;
  }

  return (
    <>
      <IconButton
        mx="auto"
        height="1rem"
        width="1rem"
        onClick={onOpen}
        minWidth="1rem"
        aria-label={intl.formatMessage(
          {
            defaultMessage: "En savoir plus sur la caractéristique « {name} »",
          },
          {
            name: translate(roomAmenity.name),
          },
        )}
        variant="unstyled"
        icon={<Icon size="16px" icon="ms_info" color="brandPrimary.300" />}
      />

      <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>{translate(roomAmenity.name)}</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Text>{translate(roomAmenity.about)}</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              textDecoration="underline"
              onClick={onClose}>
              {intl.formatMessage({
                defaultMessage: "Fermer",
              })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
